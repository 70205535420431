import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import CloseIcon from '@mui/icons-material/Close';
import Tilt from 'react-parallax-tilt';
import Card from '@mui/material/Card';
import { CardMedia, Dialog } from '@mui/material';
import Slide from '@mui/material/Slide';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const useStyles = makeStyles({
    appbar: {
        WebkitTransition: 'all 1s ease-out',
        transition: 'all 1s ease-out',
        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        paddingLeft: '40px'

    },
    show: {
        height: '150px',
        background: 'transparent',
        //background: 'green'
    },
    hide: {
        height: '50px',
        background: '#1d242d',
        paddingLeft: '5px',

    },
    articlesContainer: {
        background: "rgb(29, 36, 45, 1)",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px'
    },
    coolTitile: {
        fontSize: '120px',
        color: "white",
        width: '100%',
        display: 'block',
        textAlign: 'center',
        fontFamily: 'Darkline'
    },
    tile_background: {
        background: 'black',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        filter: 'alpha(opacity=50)',
        opacity: .5,
    },
    textContent: {
        positon: 'relative',
        bottom: 0,
        width: '80%',
        maxWidth: '900px',
        fontSize: '24px',
        color: "#7d7b8d",
        fontFamily: 'Georgia, serif',
        lineHeight: '30px',
        fontWeight: '400',
        fontSize: '18px'
    },
    video: {
        position:"fixed",
        left: '50%', /* % of surrounding element */
        top: '50%',
        transform: 'translate(-50%, -50%)', /* % of current element */
        height: '100%',
        width: '100%',
        right: 0,
        bottom: 0,
        zIndex: -1,
        objectFit: 'cover',
        backgroundSize: 'cover'
    },
    galleryTiles: {
        //background: 'rgba(119, 47, 26, 0.8)',
        //marginTop: '30px',
        width: '80%',
        maxWidth: '900px',
        background: "rgb(29, 36, 45, 1)",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",
        //display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px'
    },
    galleryHolder: {
        background: 'rgba(77, 47, 71, 0.95)',
        height: '100%'
    },
    close: {
        textAlign: 'right',
        color: 'white',
        fontSize: '30px',
        cursor: 'pointer',
        height: '50px',
        position: 'relative',

        padding: '5px',
    },
    imageTile: {
        width: '100%',
        height: 'auto',
        cursor: 'pointer'
    },
    tilesHolder: {
        background: "rgb(29, 36, 45, 1)",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '40px'
    },
    letvichka: {
        width: '40%',
        margin: 'auto',
        borderTop: '17px solid #420240'
    }

})


const images = [
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_1.jpg',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_2.jpg', 
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_3.png',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_4.jpg',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_6.jpg',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_7.jpg',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_8.jpg',
    'https://storage.googleapis.com/evgenygenchev_static/about_gal/Gallery_9.jpg'
  ];

export default function About(props) {
    const [show, setShow] = useState(true);
    const classes = useStyles();
    const [articles, setArticles] = useState([])
    const [windowHeight, setWindowHeight] = useState(0);
    const [galleryOpen, setGalleryOpen] = useState(false);
    const [startIndex, setStartIndex] = useState(0);


    
    const handleClose = () => {
        setGalleryOpen(false);
        document.body.style.overflow = 'unset';
        document.body.style.paddingRight = '0px';
    }
    return (

        <div>


            {galleryOpen &&
                <Lightbox
                    mainSrc={images[startIndex]}
                    nextSrc={images[(startIndex + 1) % images.length]}
                    prevSrc={images[(startIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setGalleryOpen(false)}
                    onMovePrevRequest={() =>
                        setStartIndex((startIndex + images.length - 1) % images.length)

                    }
                    onMoveNextRequest={() =>
                        setStartIndex((startIndex + 1) % images.length)

                    }
                />
            }
            <div className={classes.sectionContainer}>
            <div style={{width: '100%', height: '17px', background: 'rgb(29, 36, 45, 1)'}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
            
                <div className={classes.articlesContainer}>
                
                    <div className={classes.textContent}>

                        <p>Pianist Evgeny Genchev has gained wide recognition and critical acclaim as a musician, composer, educator, actor and writer.
                            He is equally happy performing for millions of people at the Brandenburg Gate for Welcoming 2017, as he is when producing music
                            in the studio or being in front of the camera.</p>

                        <p>Named by critics as “the lion of the keyboard”, Evgeny has sold-out concerts across Europe, North America, Asia and Australia.
                            He has shared the stage with musicians such as Sir Tom Jones, Helene Fischer, Filip Kirkorov and Andreas Gabalier. Evgeny has had
                            multiple appearances on various TV channels such as RTL, ARD, ZDF, BBC, Deutsche Welle, Pro7 as well as performing at the Heidi Klum’s
                            Next Top Model TV show. Genchev has recently formed a partnership with the member of the internationally acclaimed group 2Cellos,
                            Luka Šulić, with whom he embarks on regular tours around the world.</p>

                        <p>Evgeny has received multiple national and international awards including the Dobrin Petkov Grand Prize for his achievements and
                            contribution to music. He is an alumni of the prestigious Royal Academy of Music, London and the Guildhall School of Music &amp; Drama.</p>

                        <p>Besides his life as a multi-dimensional artist, Genchev is a partner in the creative company 2927 that specialises in design,
                            digital, film, photography, and has worked with some of the largest names like Pepsico, Gillette, Times of India, L’Oréal, Nivea, Men’s Health,
                            Shahrukh Khan, Aishwarya Rai, Amitabh Bachchan, Deepika Padukone, Leander Paes, Rahul Dravid, various politicians and sportspersons.</p>
                    </div>
                    
                </div>
                
                

                 
            </div>
            <div>
            <div className={classes.sectionContainer}>


                    <div className={classes.articlesContainer}>
                    

                        <div className={classes.textContent}>
                            <Grid container spacing={6}>
                                
                                {images.map((i, ind) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={3}>
                                            <Card sx={{ maxWidth: 345 }} onClick={() => { setStartIndex(ind); setGalleryOpen(true) }} className={classes.imageTile}>
                                                <CardMedia
                                                    component="img"
                                                    height="150"
                                                    image={i}
                                                    alt="Click to enlarge"
                                                />
                                            </Card>
                                        </Grid>
                                    )

                                })}
                            </Grid>
                        </div>
                    </div>
                </div>
               
                
                
            
            </div>
            
            
            
        </div>
    );
}