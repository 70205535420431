import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";
import FullArticle from './FullArticle';
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { Try } from '@mui/icons-material';
import { gsap } from 'gsap';
import CloseIcon from '@mui/icons-material/Close';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Paper } from '@mui/material';

import { keyframes } from '@mui/system';
import { resolvePath, useParams, Routes, Route, Outlet } from 'react-router-dom';
import SERVER from './URLS';

const storage = getStorage();
const basePathArticles = 'articles'
const articlesRef = ref(storage, basePathArticles);

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const useStyles = makeStyles({
    appbar: {

        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        paddingLeft: '40px'

    },

    show: {
        height: '150px',
        background: 'transparent',
        //background: 'green'
    },
    hide: {
        height: '50px',
        background: '#1d242d',
        paddingLeft: '5px',

    },
    articlesContainer: {
        /*background: "#17191e",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",*/
        background: "rgb(29, 36, 45, .8)",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'stretch',
        display: 'block',

        padding: '60px'
    },
    coolTitile: {
        fontSize: '120px',
        fontFamily: 'Darkline',
        color: "white",
        width: '100%',
        display: 'block',
        textAlign: 'center'
    },
    article: {

        //display: 'block',
        background: 'rgba(0,0,0, .5)',
        //position: 'relative',
        width: '100%',

        textAlign: 'center',

        //float: 'left',

    },
    tile_background: {
        background: 'black',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        filter: 'alpha(opacity=50)',
        opacity: .5,
    },
    articleBig: {
        color: 'white',
        backgroundColor: "rgba(33,33,33,.9)",
        zIndex: 10000,
        position: 'fixed',
        bottom: 0,
        top: 0,
        width: '100vw', /* viewport width */
        height: '100vh', /* viewport height */

        justifyContent: 'center',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    articleHeader: {
        color: '#6f1e9c',
        textAlign: 'right',
        height: '40px',
        padding: '10px',
    },
    section_heading: {
        opacity: 0.8,
     fontSize: '24px',
     fontFamily: 'Abel',
     color: 'white',
     background: '#4B4E53',
     textAlign: 'center',
     margin: '10px',
     padding: '10px',
    },
    article_inner: {
        background: '#3f3f3f',
        color: '#95A5A6',
        fontSize: '20px',
        padding: '3%',
        width: '95%',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
        marginLeft: 'auto',
        marginRight: 'auto',
        "&:-webkit-scrollbar": {
            display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }


})



export default function ParticipantsTable() {
    const [paricipansInfo, setParticipantsInfo] = useState([])
    const classes = useStyles()


    useEffect(()=>{
        //load articles on load
        axios.get(SERVER + '/list_payments_ssf').then(res=>{
            setParticipantsInfo(res.data.payments);
        })
      },[])
    
   

    return (
        <>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell align="left">Payment ID</TableCell>
            <TableCell align="left">Date</TableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {paricipansInfo.map((row) => (
            <TableRow
              key={row.title}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.payment_id}
              </TableCell>
              <TableCell component="th" scope="row">
               {row.day?row.day:'NA'}
              </TableCell>
              
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            
            
        </>
    );
}