import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/evgeny_genchev_white.png';
import MenuIcon from '@mui/icons-material/Menu';
import useWindowDimensions from '../hooks/WindowDimensions';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useLocation
} from "react-router-dom";

import { makeStyles } from '@mui/styles';
import FullScreenMenu from './FullScreenMenu';
import { Button, IconButton } from '@mui/material';



const useStyles = makeStyles({
    appbar: {
        WebkitTransition: 'all 1s ease-out',
        transition: 'all 1s ease-out',
        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        color: 'white',
        fontSize: '30px',
        padding: '10px'
    },

    show: {
        height: '150px',
        background: 'rgb(23, 25, 30, 0.0)',
        //background: 'green'
    },
    hide: {
        height: '80px',
        background: '#17191e',
        paddingLeft: '5px',

    },
    logo: {
        WebkitTransition: 'all 1s ease-out',
        transition: 'all 1s ease-out',
    },
    logo_show: {
        width: '260px',
        height: 'auto',
        '@media(min-width: 400px)' : {
            width: '300px',
          },
    },
    logo_hide: {
        width: '230px',
        height: 'auto'
    },
    link: {
        WebkitTransition: 'all 1s ease-out',
        transition: 'all 1s ease-out',
        color: 'white',
        display: 'block',
        width: '50px',
        float: 'right',
        padding: '5px',
        textDecoration: 'none',
        cursor: 'pointer',
        background: '',
        textAlign: 'center',
        margin: '5px',
        fontFamily: "Fredericka the Great",
        fontSize: '20px',
        transform: 'scale(1.5) translate(-10px, 0px)'
    }
})



export default function TopNav({onSubscribe}) {
    const [show, setShow] = useState(true);
    const [openmenu, setOpenMenu] = useState(false);
    const classes = useStyles();
    var lastScroll = 0;
    const [windowHeight, setWindowHeight] = useState(0);
    const { height, width } = useWindowDimensions();
    const location = useLocation();
    useEffect(() => {
        setOpenMenu(false);
    }, [location])

    useEffect(() => {
        setWindowHeight(window.innerHeight);
    })
    const escFunction = (event) => {
        if(event.keyCode === 27) {
            setOpenMenu(false)
        }
      }
      const closeOnClick = (event) => {
        
            setOpenMenu(false)
        
      }
    useEffect(() => {

        window.addEventListener('scroll', handleScroll, { passive: true });
        window.addEventListener('keydown', escFunction);


        return()=>{
            window.removeEventListener('scroll', handleScroll);
            document.removeEventListener("keydown", escFunction, false);
        }
            
        
    }, []);

    const handleScroll = (evt) => {

        const currentScroll = window.pageYOffset;

        if (currentScroll === lastScroll) {
            return;
        }
        const testShouldShow = currentScroll < 250 ? true : false;


        setShow(testShouldShow)


        lastScroll = currentScroll;
    }
 
    let wrapperRef = useRef(null);

        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setOpenMenu(false)
                }
            }
    
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        },[wrapperRef]);
  
    
    
    return (
        <>
        <div ref={wrapperRef}>
            <div className={`${classes.appbar} ${show == true ? classes.show : classes.hide}`}>
                    <Link to="/"><img src={logo} className={`${classes.logo} ${show == true ? classes.logo_show : classes.logo_hide}`}/></Link>
                   <MenuIcon onClick={() => setOpenMenu(true)} fontSize="large" className={classes.link}/>

            </div>
            <div>
                <FullScreenMenu open={openmenu} onclose={() => { setOpenMenu(false) }} onSubscribe={onSubscribe}/>
            </div>
        </div>
        </>
    );
}