import React from 'react';
import { Grid } from '@mui/material';
import logo from '../images/EG_logo.png';
import instagram_logo from '../images/social_icons/instagram.png';
import facebook_logo from '../images/social_icons/facebook.png';
import twitter_logo from '../images/social_icons/twitter.png';
import medium_logo from '../images/social_icons/medium.png';
import youtube_logo from '../images/social_icons/youtube.png';
import spotify_logo from '../images/social_icons/spotify.png';
import apple_logo from '../images/social_icons/apple.png';
import patreon_logo from '../images/social_icons/patreon.png';
import subscribe_logo from '../images/social_icons/bell.png';
import { makeStyles } from '@mui/styles';
import SocialLink from './SocialLink';
import {
    Link,
   } from "react-router-dom";

const useStyles = makeStyles({
    footer: {
        background: '#0f1116',
        color: 'white',
        marginTop: '20px'
    },
    social_links: {
        overflow: 'hidden',
        height: 'auto',
        background: '#1b232b',
        color: "white",
        width: '100%',
        float: 'left',
        display: 'flex'
    },
    social_link: {

        display: 'block',
        padding: '1px',
        //float: "right",
        background: "rgba(255,255,255,.6)",
        borderRadius: '10px',
        margin: '1px',
        cursor: 'pointer'
    },
    botomMenu: {
        //height: '100px',
        position: 'relative',
        float: "right",
        marginTop: '10px',
        textAlign: 'center !important',
        //direction: 'rtl',
        //transform: "translate(150px, -100px)",
        top: 0,
        right: 0,
        width: '100% !important'
    },
    credits: {
        textAlign: 'center',     
        padding: '20px',
        background: 'black',
        fontFamily: 'Abel'
    },
    link: {
        //background: 'rgba(0,0,0,0.9)',
        color: 'white',
        //display: 'block',
        //width: '150px',
        display: 'inline-block',    
        //float: 'left',
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingTop: '5px',
        paddingBottom: '5px',

      
        textDecoration: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        margin: '5px',
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '13px',
        //padding: '5px',
        '&:hover':{
            color: "yellow"
        }
     },
     privacy: {
        color: 'white',
        //display: 'block',
        //width: '150px',
        display: 'inline-block',    
        //float: 'left',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingTop: '5px',
        paddingBottom: '5px',

      
        textDecoration: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        margin: '5px',
        fontFamily: "Montserrat",
        fontWeight: '600',
        fontSize: '13px',
        //padding: '5px',
        '&:hover':{
            color: "yellow"
        }
     },
     socials_holder: {
        textAlign: 'center',
        width: "100%",
        height: 'auto',
        transform: 'translate(0px, 0px)'
     },
     social_row: {
        textAlign: "center",
     }
})



export default function Footer({onSubscribe}) {

    const classes = useStyles();

    const links = [
        {   
            img: instagram_logo,
            link: 'https://www.instagram.com/evgenygenchev/',
            text: "Instagram",
        },
        {   
            img: facebook_logo,
            link: 'https://www.facebook.com/EvgenyGenchev',
            text: "Facebook",
        },
        {   
            img: twitter_logo,
            link: 'https://twitter.com/Evgeny_Genchev',
            text: "Twitter",
        },
        {   
            img: medium_logo,
            link: 'https://medium.com/@evgenygenchev',
            text: "Medium",
        },
        {   
            img: youtube_logo,
            link: 'https://www.youtube.com/evgenygenchev',
            text: "Youtube",
        },
        {   
            img: spotify_logo,
            link: 'https://open.spotify.com/artist/6n7ZUZfIHRIJU23LuIzTlx?si=BCoLK97gQ7Ge2B1xoBx62w&nd=1',
            text: "Spotify",
        },
        {   
            img: apple_logo,
            link: 'https://music.apple.com/us/artist/evgeny-genchev/1546386935',
            text: "Apple Music",
        },
        {   
            img: patreon_logo,
            link: 'https://www.patreon.com/evgenygenchev/posts',
            text: "Patreon",
        },
        {   
            img: subscribe_logo,
            link: 'SUBSCRIBE',
            text: "SUBSCRIBE",
            
        },
    ]

    return (
        <div className={classes.footer}>
            <Grid container spacing={1}>
                <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                    <img src={logo} height={100} />
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className={classes.botomMenu}>
                            
                                <Link to='/' className={classes.link}>
                                    HOME
                                </Link>

                                <Link to='/about' className={classes.link}>
                                    ABOUT
                                </Link>
                                <Link to='/videos' className={classes.link}>
                                    VIDEOS
                                </Link>
                                <Link to='/music' className={classes.link}>
                                    MUSIC
                                </Link>
                                <Link to='/contact' className={classes.link}>
                                    CONTACT
                                </Link>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.socials_holder}>

                            
                                {links.map((l,indl)=>{
                                        
                                        return(
                                            <SocialLink img={l.img} link={l.link} text={l.text} key={`social_link_${indl}`} action={l.text=="SUBSCRIBE"?onSubscribe:null}/>
                                        )
                                 
                                    
                                })}
                          
                            
                            </div>
                        </Grid>
                    </Grid>
                
                </Grid>
                <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
                    
                </Grid>
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                    
                        
                    </Grid>
            </Grid>
            
            
           
        
            
        

              
            <div className={classes.credits}>
                <Grid container>
                    <Grid item md={4} sm={12} xs={12}>
                    DESIGN: <a href="http://www.two9two7.com/blog/" target="_blank"style={{color: 'white', textDecoration: 'none'}} className={classes.privacy}>two9two7</a>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                    <Link to='/privacy' className={classes.privacy}>
                                    Privacy Policy
                                </Link>
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <span dangerouslySetInnerHTML={{ "__html": `&copy; Evgeny Genchev ${(new Date()).getFullYear()}. All rights reserved` }} />
                    </Grid>
                    
                </Grid>
                
            </div>
            
                
                
          
        </div>
    );
}