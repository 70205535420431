import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { resolvePath } from 'react-router-dom';
import { Try } from '@mui/icons-material';
import {gsap} from 'gsap';
import CloseIcon from '@mui/icons-material/Close';

const storage = getStorage();
const basePathArticles = 'articles'
const articlesRef = ref(storage, basePathArticles);

const useStyles = makeStyles({
    appbar: {

        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        paddingLeft: '40px'

    },

    show: {
        height: '150px',
        background: 'transparent',
        //background: 'green'
    },
    hide: {
        height: '50px',
        background: '#1d242d',
        paddingLeft: '5px',

    },
    articlesContainer: {
        /*background: "#17191e",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",*/
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: '-webkit-box',
        display: '-moz-box',
        display: '-ms-flexbox',
        display: '-moz-flex',
        display: '-webkit-flex',
        display: 'flex',
        flexWrap: 'wrap',
    },
    coolTitile: {
        fontSize: '120px',
        fontFamily: 'Darkline',
        color: "white",
        width: '100%',
        display: 'block',
        textAlign: 'center'
    },
    article: {
  
        //display: 'inline-block',
        background: 'rgba(90,90,90, .3)',
        position: 'relative',
        width: '300px',
        height: '400px',
        margin: '1em',
        border: '1px solid #3e3e3e',
        textAlign: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        display: 'flex'

    },
    tile_background: {
        background: 'black',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        filter: 'alpha(opacity=50)',
        opacity: .5,
    },
    articleBig: {
        color: 'white',
        backgroundColor: "rgba(33,33,33,.9)",
        zIndex: 10000,
        position: 'fixed',
        bottom: 0,
        top: 0,
        width: '100vw', /* viewport width */
        height: '100vh', /* viewport height */

        justifyContent: 'center',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    articleHeader: {
        color: '#6f1e9c',
        textAlign: 'right',
        height: '40px',
        padding: '10px',
    },
    section_heading: {
        opacity: 0.8,
        fontSize: '24px',
        fontFamily: 'Abel',
        color: 'white',
        background: '#4B4E53',
        textAlign: 'center',
        margin: '10px',
        padding: '10px',
    },
    article_inner: {
        background: '#3f3f3f',
        color: '#95A5A6',
        fontSize: '20px',
        padding: '3%',
        width: '80%',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
        marginLeft: 'auto',
        marginRight: 'auto',
        "&:-webkit-scrollbar":{
            display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }


})



export default function NotFound(props) {
    const [show, setShow] = useState(true);
    const classes = useStyles();
    const [articles, setArticles] = useState([])
    const [windowHeight, setWindowHeight] = useState(0);
    const [activeArticle, setActiveArticle] = useState(null);

    useEffect(() => {
        setWindowHeight(window.innerHeight);
    })


    return (

        <>

            <div className={classes.sectionContainer}>
                <div className={classes.section_heading}>The page you are looking for is not part of the valid URLs for this site.</div>
            </div>
        </>
    );
}