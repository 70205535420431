import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button, Grid, TextField } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { resolvePath } from 'react-router-dom';
import { Try } from '@mui/icons-material';
import { gsap } from 'gsap';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import { $, jQuery } from 'jquery'
import ReactPlayer from 'react-player';
import Tooltip from '@mui/material/Tooltip';
import qs from 'qs';
import spotifyImage from '../images/social_icons/spotify.svg'
import SERVER from './URLS';
import ReCAPTCHA from "react-google-recaptcha";

const storage = getStorage();
const basePathArticles = 'articles'
const articlesRef = ref(storage, basePathArticles);

const useStyles = makeStyles({

  
  section_heading: {
    opacity: 0.8,
    fontSize: '24px',
    fontFamily: 'Abel',
    color: 'white',
    background: '#4B4E53',
    textAlign: 'center',
    margin: '10px',
    padding: '10px',
  },
  articlesContainer: {
    background: "#252b33",
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    backgroundPosition: "center center",
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center'
  },
  coolTitile: {
    fontSize: '120px',
    color: "white",
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Darkline'
  },

  tile_background: {
    background: 'black',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1,
    filter: 'alpha(opacity=50)',
    opacity: .5,
  },
  textContent: {

    width: '60%',
    margin: "auto",
    fontSize: '24px',
    color: "white",
    fontFamily: 'Abel',
    paddingTop: '40px',
    paddingBottom: '40px',
    

  },
  video: {
    position: "fixed",
    left: '50%', /* % of surrounding element */
    top: '50%',
    transform: 'translate(-50%, -50%)', /* % of current element */
    zIndex: -1,
  },
  galleryTiles: {
    padding: '5%',
    background: 'rgba(119, 47, 26, 0.8)',
    marginTop: '30px'
  },
  galleryHolder: {
    display: 'block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    background: 'rgba(77, 47, 71, 0.95)',
    zIndex: 10001,
  },
  close: {
    textAlign: 'right',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    height: '50px',
    position: 'relative',

    padding: '5px',
  },
  imageTile: {
    width: '100%',
    height: 'auto',
  },
  sectionContainer: {
    marginBottom: '40px'
  },
  video_tile: {
    background: 'black',
    padding: '5px',
    height: "100%",
    cursor: "pointer",
  },
  image_thumb: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    position: 'relative',
    display: 'inline-block'
  },
  fullWidthVideo: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,.9)",
    zIndex: 10000,
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100vw', /* viewport width */
    height: '100vh', /* viewport height */

    justifyContent: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  fullVideoContainer: {
    width: '80%',
    height: '80%',
    padding: '3%',
    margin: 'auto',
  },
  videoHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },
  album_tile: {
    background: "rgba(0,0,0,0.5)",
    padding: '10px',
    margin: '5px',
    width: '100%'
  },
  track_separator: {
    background: 'rgba(38, 64, 139, 0.5)',
    width: '100%',
    textAlign: 'center',
  },
  tracks_container: {
    padding: '20px'
  },
  track: {
    transition: 'all 0.5s ease',
    padding: '10px',
    borderRadius: '10px',
    '&:hover': {
      color: 'yellow',
      background: 'rgba(129, 177, 213, 0.5)',
    }
  },
  albumTitle: {
    padding: '5px',
    background: "rgba(0,0,0,0.6)"
  },
  video: {
    position: "fixed",
    left: '50%', /* % of surrounding element */
    top: '50%',
    transform: 'translate(-50%, -50%)', /* % of current element */
    zIndex: -1,
  },
  white_txt: {
    background: '#373E4A',
    color: 'white !important',
    fontFamily: 'Montserrat',
    fontWeight: '500'
  },
  txtLabel: {
    fontFamily: 'Georgia, serif',
    fontWeight: '400',
    fontSize: '18px',
    color: '#7d7b8d'
  },
  letvichka: {
    width: '40%',
    margin: 'auto',
    borderTop: '17px solid #420240'
  }

})

 

export default function ContactForm(props) {
  const classes = useStyles();
  const [windowHeight, setWindowHeight] = useState(0);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const [valid, setValid] = useState(false);

  const captcha = useRef(null);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  })


  function onChangeCaptcha(value) {
    setValid(true);
  }

  const handleSave = () => {
    captcha.current.reset();
    axios.post(SERVER + '/send_contact_mail', {
      name: name,
      email: email,
      subject: subject,
      content: content,
    }).then(res=>{
      setName('');
      setEmail('');
      setSubject('');
      setContent('');
  })}

  return (
    <div>
      <div className={classes.sectionContainer}>
      <div style={{
       backgroundSize: 'cover',
       WebkitBackgroundSize: 'cover',
       backgroundImage: `url('../contact_background.jpg')`,
       backgroundPosition: "center center",
       backgroundRepeat: 'no-repeat',
       position: 'fixed',
       top:0,
       left:0,
       zIndex: -99,
       width: '100%',
       height: `${windowHeight + 100}px`,
       color: 'white',
       position: 'fixed'
     }}>
       


     </div>
        
      </div>
      <div className={classes.sectionContainer}>
      <div style={{width: '100%', height: '17px', background: "#252b33"}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
        <div className={classes.articlesContainer}>
        
        <div className={classes.textContent}>
            <h3>EvgenyGenchev.net Privacy Policy</h3>

            The following gives a simple overview of what happens to your personal information when you visit our website. 
            Personal data is any data with which an individual using the website can be directly or indirectly identified. 
            
            <h3>Data collection on EvgenyGenchev.net</h3>
            This website allows anyone who enters it to subscribe for a newsfeed, by providing basic contact info. By subscribing for a newsfeed, anyone is thereforе allowing the following:
            <ol>
             <li>Usage of cookies, to track the subscription status</li>
             <li>Storing their name and email in a database, used only for sending targeted messages, related to new releases, articles or products.</li>
            </ol>


            <p>Products may mean: albums, merch, tickets, etc. that we are releasing or will be releasing in the future.</p>
         </div>
        </div>
      </div>
    </div>
  );
}