import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../fonts/Darkline/Darkline.ttf';
import { makeStyles } from '@mui/styles';
import {gsap} from 'gsap';
import {Helmet} from "react-helmet";
import ClipLoader from "react-spinners/ClipLoader";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import app from '../firebase.js'
import { useCookies } from 'react-cookie';
import { getDatabase, ref, set, update, push } from "firebase/database";
import logo_small from '../images/ev_favicon_2.png';
import { withStyles } from "@mui/styles";
import SERVER from './URLS';
const database = getDatabase(app);


const StyledButton = withStyles({
  root: {
    fontFamily: 'Abel !important'
  },
 
})(Button);

const StyledDialog = withStyles({
  root: {
    background: 'transparent !important'
  },
  paper: {
    background: "rgba(255,255,255,0.6) !important"
  }
})(Dialog);

const StyledDialogContent = withStyles({
  root: {
    background: "rgba(255,255,255,0.9) !important"
  }
})(DialogContent);

const StyledDialogContentText = withStyles({
  root: {
    background: 'transparent !important'
  }
})(DialogContentText)

const useStyles = makeStyles({
  dialog: {
    background: 'rgba(0,0,0,0.3)'
  },
  dialog_title: {
    background: "rgba(15,15,15,0.6)",
    color: 'white'
  },
  title_inner:{
    padding: "0px 30px",
    fontFamily: 'Abel',
    fontSize: '25px !important'
  },
  dialog_content: {
    background: "rgba(55,55,55,0.6)",
    padding: "30px !important",
    fontWeight: 'bolder !important',
    fontFamily: 'Abel',
  },
  dialog_actions: {
    background: "rgba(55,55,55,0.6)",
    fontFamily: 'Abel',
  },
  dialog_content_text:{
    fontWeight: 'bolder !important',
    fontFamily: 'Abel !important',
    fontSize: '20px !important'
  }
})

 
export default function SubscribeForm({open, setOpen}) {

  const [cookies, setCookie] = useCookies(['subscribed_evgenygenchev','suppressed_evgenygenchev']);
  const classes = useStyles();
  


  
  const[subscribe_email, setSubscribeEmail] = useState('')
  const[subscribe_name, setSubscribeName] = useState('')

  const handleClose = () => {
    setCookie('suppressed_evgenygenchev', true, {maxAge: 864000});
    setOpen(false);
  };


  
  const handleSubscribe = () => {
    
    
    axios.post(SERVER + '/subscribe', {
      email: subscribe_email,
      name: subscribe_name,
      active: true
    }).then(res=>{
      setCookie('subscribed_evgenygenchev', true, {maxAge: 86400000});
      setOpen(false);
  })
  }

   
  return (
       <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>
          <img src={logo_small} style={{verticalAlign: 'middle'}}/>
          <span className={classes.title_inner}>JOIN EVGENY’S MAILING LIST!</span>
        </DialogTitle>
        <StyledDialogContent className={classes.dialog_content}>
          <StyledDialogContentText className={classes.dialog_content_text}>
          Be the first to know about new releases, tour dates and get all the latest news!
          </StyledDialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="email"
            fullWidth
            variant="standard"
            value={subscribe_name}
            onChange={(e)=>{setSubscribeName(e.target.value)}}
          />
          <TextField
            autoFocus
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            value={subscribe_email}
            onChange={(e)=>{setSubscribeEmail(e.target.value)}}
          />
        </StyledDialogContent>
        <DialogActions>
          <StyledButton onClick={handleClose} variant="contained">Ignore</StyledButton>
          <StyledButton onClick={handleSubscribe} variant="contained">Subscribe</StyledButton>
        </DialogActions>
      </StyledDialog>

  );
}


