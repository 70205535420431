import { Grid } from '@mui/material';

import React, { useState, useEffect, useRef, useMemo, Suspense } from 'react';

import { Parallax } from 'react-parallax';

import {
  Link,
} from "react-router-dom";

import { makeStyles } from '@mui/styles';
import ssf_logo_small from '../images/Sweet_Struggle_FESTIVAL_logo_small.png'
import ssbg from '../images/ss_bgd.jpg'
import Button from '@mui/material/Button';
import { Fade, Slide } from "react-awesome-reveal";

const useStyles = makeStyles({
  main_content: {
    background: '#1d242d',
  },
  hcenter: {
    WebkitTransition: 'all .2s ease-out',
    transition: 'all .2s ease-out',
    left: '50%',
    top: '50%',
    position: 'absolute',
    fontSize: '120px',
    color: "white",
    textAlign: 'center',
    fontFamily: 'Darkline',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: 'grey',
  },
  preloader: {
    display: 'none'
  },
  shutterHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%'
  },
  leftShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',

  },
  rightShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
  },
  btn_link: {
    background: 'transparent',
    textDecoration: 'none',
    color: 'white',
    padding: '10px',
    //borderRadius: '10px',
    border: '5px solid white',
    fontSize: '20px',
    marginTop: '20px',
    display: 'inline-block'
  },
  expand: {
   WebkitTransition: 'all 1s ease-out',
   transition: 'all 1s ease-out',
   transform: 'scale(1.5)'
  },
  shrink: {
   WebkitTransition: 'all 1s ease-out',
   transition: 'all 1s ease-out',
   transform: 'scale(1)'
  },
  jumbotron: {
    WebkitTransition: 'all .2s ease-out',
    transition: 'all .2s ease-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
   // padding: '50px',
    color: 'white',
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: '50px',
    backgroundSize: 'cover',
    fontFamily: 'Abel',
    '@media(min-width: 400px)' : {
      fontSize: '40px',
    },
    '@media(min-width: 1330px)' : {
      fontSize: '60px',
    },
   
     //opacity: 0.4,
     position: "absolute",
     width: '100%',
     height: '100%',
   
  top: '0%',
  
  //transform: 'translateY(-100%)',
    
  },
  jumbo_inner: {
   opacity: 1,
   zIndex: '+3',
   position: 'relative',
    top: '0',
    left: '0',
    //transform: 'translate(0, 50%)',
    height: 'auto'
  },
  bigletter: {
    fontSize: '40px',
    '@media(min-width: 400px)' : {
      fontSize: '60px',
    },
    '@media(min-width: 1330px)' : {
      fontSize: '100px',
    }
  },
  closeBTN: {
    background: 'red',
    color: 'white',
  },
  backgroundImage: {
   opacity: 0.6,
   position: 'absolute',
   left: 0,
   top: 0,
   width: '100%',
   height: '100%'
  }
})

function JumboFest(){
  const classes = useStyles();
  const [show, setShow] = useState(true);
  const [action, setAction] = useState(true);


  useEffect(()=>{
   setTimeout(()=>{
    setAction(!action)
   },1000)
  },[action])

  return (
    <>
    
    {show && 
      <div className={classes.jumbotron}>
       <img src='../ss_bgd.jpg' className={classes.backgroundImage}/>
       <Slide delay={0} duration={2000}  direction="up">
       <div className={classes.jumbo_inner}>
       {false && <div style={{textAlign: "right"}}>
      <Button onClick={()=>setShow(false)} variant="contained" className={classes.closeBTN}>Close</Button></div>}
      <Grid container spacing="1" alignItems="center" justifyContent="center">
        <Grid item xl={3} md={3} sm={12}>
          7-13 <span className={classes.bigletter}>A</span>UGUST <br/>2023
        </Grid>
        <Grid item xl={6} md={6} sm={12}>
          <img src={ssf_logo_small} style={{ width: "100%", maxWidth: "900px" }} />
        </Grid>
        <Grid item xl={3} md={3} sm={12}>
        <span className={classes.bigletter}>P</span>LOVDIV <br/><span className={classes.bigletter}>B</span>ULGARIA
        </Grid>
        <Grid item xs={12}>
          <Link to="/ssf" className={`${classes.btn_link} ${action?classes.expand:classes.shrink}`}>
          LEARN MORE
          </Link>
        </Grid>
      </Grid>
      
       
      </div>
      </Slide>
    </div>
    }
    </>
  );
}


export default JumboFest;
