import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArticleForm from './ArticleForm';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, uploadString } from "firebase/storage";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button } from '@mui/material';
import { fontSize } from '@mui/system';
import TourDatesForm from './TourDatesForm';
import ArticlesTable from './ArticlesTable';
import ParticipantsTable from './ParticipantsTable'
import MailTemplateForm from './MailTemplateForm'
import SERVER from './URLS';
const useStyles = makeStyles({
 panel: {
   background: "rgba(255,255,255,0.9)",
   color: "white",
   fontSize: '22px',
   textAlign: "center"
  },
  tabHeading: {
   textColorPrimary: "white"
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AuthForm = (props) => {
  const [name, setName] = React.useState('');
  const [pass, setPass] = React.useState('');

  const [err, setErr] = React.useState('')

  const Authenticate = () => {
    if(!name.length || !pass.length){
      setErr("Please provide user name and password!")
    }else{
      let data = {
        'name': name,
        "pass": pass
      }
      axios.post(SERVER + '/admin_auth',data).then(res=>{
        props.setAuth(res.data.success)
      })
    }
  }

  return(
    <>
      <TextField
        id="name"
        label="Admin Name"
        value={name}
        onChange={e=>setName(e.target.value)}
        fullWidth
      />
      <TextField
        id="name"
        label="Admin Pass"
        value={pass}
        onChange={e=>setPass(e.target.value)}
        fullWidth
        type="password"
      />
      <Button onClick={Authenticate} variant="contained">Auth</Button>
     </>
  )
}

export default function Admin() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const [auth, setAuth] = React.useState(false);
  const [editedArticle, setEditedArticle] = React.useState(null)
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setAuthenticated = (isauth) => {
    setAuth(isauth)
  }

  const setEdited = (article) => {
    setEditedArticle(article)
  }

  return (
    <Box sx={{ width: '100%' }} className={classes.panel}>
      {!auth &&
        <AuthForm setAuth={setAuthenticated} />
      }
      {
        auth &&
        <>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Articles" {...a11yProps(0)} className={classes.tabHeading} />
              <Tab label="Tour Dates" {...a11yProps(1)} />
              <Tab label="Sweet Struggle Participants" {...a11yProps(2)} />
              <Tab label="Send Email" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ArticlesTable onSetEdit={setEdited}/>
            <ArticleForm article={editedArticle}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TourDatesForm/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ParticipantsTable/>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <MailTemplateForm/>
          </TabPanel>
        </>
      }
    </Box>
      
      
  );
}