import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import Tilt from 'react-parallax-tilt';
import { Button } from '@mui/material';
import Card from '@mui/material/Card';
import { CardMedia } from '@mui/material';
import ImageGallery from 'react-image-gallery';
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { resolvePath, useParams, Routes, Route, Link, useNavigate, useLocation  } from 'react-router-dom';
import { gsap } from 'gsap';
import { Helmet } from "react-helmet";
import CloseIcon from '@mui/icons-material/Close';

import eg_cosial_icon from '../images/evve_face.png';
import fah_cosial_icon from '../images/farah_face.png';

import instagram_logo from '../images/social_icons/instagram.png';
import facebook_logo from '../images/social_icons/facebook.png';
import twitter_logo from '../images/social_icons/twitter.png';
import medium_logo from '../images/social_icons/medium.png';
import youtube_logo from '../images/social_icons/youtube.png';
import spotify_logo from '../images/social_icons/spotify.png';
import apple_logo from '../images/social_icons/apple.png';
import patreon_logo from '../images/social_icons/patreon.png';
import subscribe_logo from '../images/social_icons/bell.png';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import SERVER from './URLS';

import { Divider } from '@mui/material';

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton, 
  WhatsappShareButton
} from "react-share";

import { keyframes } from '@mui/system';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const storage = getStorage();


const useStyles = makeStyles({


  article: {
    background: 'rgba(0,0,0, .5)',
    width: '100%',
    textAlign: 'center',
  },

  articleBig: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,1)",
    zIndex: '+100',
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100vw', /* viewport width */
    height: '100vh', /* viewport height */

    justifyContent: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },

  articleHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },

  article_inner: {
    fontFamily: 'Abel',
    background: 'rgba(33,33,33,1)',
    color: '#95A5A6',
    fontSize: '20px',
    padding: '10px',
    width: '90%',
    height: "100%",
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    marginLeft: 'auto',
    marginRight: 'auto',
    "&:-webkit-scrollbar": {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  },
  section_heading: {
    fontSize: '100px',
    fontFamily: 'Darkline',
    color: 'white',
    background: 'rgba(53, 0, 87, 0.0)',
    textAlign: 'center',

    //paddingTop: '10px',
    //paddingBottom: '10px',
    width: '100%',
    //borderRadius: '10px',
    //border: "1px solid yellow"
    //borderBottomRightRadius: "10px"
  },
  close: {
    textAlign: 'right',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    height: '50px',
    position: 'relative',

    padding: '5px',
  },
  galery_div: {
    background: 'rgba(255,255,255,0.5)',
    width: "94% !important",
    padding: '3%',


  },
  galleryHolder: {
    display: 'block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    background: 'rgba(77, 47, 71, 0.95)',
    zIndex: 10001,
},
close: {
    textAlign: 'right',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    height: '50px',
    position: 'relative',
    padding: '5px',
},
imageTile: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer'
},
footerContainer: {
  fontSize: '18px'
},
social_share: {
  fontFamily: "Abel",
  fontSize: '20px',
  height: "40px"
}

})


const ArticleFooter = () => {
  const classes = useStyles()
  return(
    <>
    <hr style={{color: 'white !important'}}/>
    <div className={classes.footerContainer}>
    When music found the right expression and literature found the right tone, <b>Evgeny</b> and <b>Farahdeen</b> united to create as one.
    <Grid container spacing={5} align="center" justify="top" alignItems="top">
      <Grid item md={6} xs={12}>
        <Grid container spacing={1} align="center" justify="center" alignItems="center">
          <Grid item xl={1} md={2} xs={3}>
            <img src={eg_cosial_icon} width="50"/>
            
          </Grid>
          <Grid item xl={1} md={2} xs={3}>
         
            <IconButton aria-label="twitter" color="primary" onClick={()=> window.open("https://twitter.com/evgeny_genchev?lang=en", "_blank")}>
              <TwitterIcon/>
            </IconButton>
            <IconButton aria-label="insta" color="primary"  onClick={()=> window.open("https://www.instagram.com/evgenygenchev/", "_blank")}>
              <InstagramIcon/>
            </IconButton>
            
          </Grid>
          <Grid item xl={10} md={8} xs={6}>
            Evgeny is an acclaimed pianist, composer, educator, actor and writer<br/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={0} align="center" justify="center" alignItems="center">
          <Grid item xl={1} md={2} xs={3}>
            <img src={fah_cosial_icon} width="50"/>
          </Grid>
          <Grid item xl={1} md={2} xs={3}>
            <IconButton aria-label="twitter" color="primary" onClick={()=> window.open("https://twitter.com/farahdeenkhan?lang=en", "_blank")}>
              <TwitterIcon/>
            </IconButton>
            <IconButton aria-label="insta" color="primary"  onClick={()=> window.open("https://www.instagram.com/farahdeenkhan/", "_blank")}>
              <InstagramIcon/>
            </IconButton>
          </Grid>
          <Grid item xl={10} md={8} xs={6}>
            Farahdeen is a published author, writer, a painter and poet.<br/>
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
    </>
  )
}

export default function FullArticle() {

  const classes = useStyles();
  const { uid } = useParams();
  const location = useLocation();





  useEffect(() => {
    if (uid) {
     axios.post(SERVER + '/unsubscribe', {
      uid: uid,
      }).then(res=>{
        
    })
    }
  }, [uid])


  return (
    
    <>
      
          <div className={classes.article_inner}>
            
            You've been unsusbscribed {uid}.<br/>
            You can always subscribe again using the subscribe button at the bottom of the site.

          </div>
          
          
      
      
    </>
  );
}