import React, { useEffect, useState } from 'react';
import { AppBar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { display } from '@mui/system';

const useStyles = makeStyles({
    animated_tile: {
        background: 'red',
        position: 'relative',
        opacity: 0.1,
        width: '5vw',
        minHeight: '8vh',
        transition: "all 5s ease",
        WebkitTransition: "all 5s ease",
        MozTransition: "all 5s ease",
        float: 'right',
        display: 'block'
    }
})
function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    var greybit= ''
    for (var i = 0; i < 2; i++) {
        greybit += letters[Math.floor(Math.random() * 16)];
    }
    for (var i = 0; i < 3; i++){
        color += greybit;
    }
    return color;
}

function AnimatedTile() {
    const [color, setColor] = useState(null);

    const classes = useStyles();
    useEffect(()=>{
        setColor(getRandomColor());
        setInterval(()=>{
           
            setColor(getRandomColor());
        },5000)
    },[])
    return (
        <div className={classes.animated_tile} style={{ background: color}}>

        </div>
    );
}

export default AnimatedTile;
