import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { getStorage, listAll, getDownloadURL, uploadBytes, uploadString, ref, getMetadata } from "firebase/storage";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Grid } from '@mui/material';
import { fontSize } from '@mui/system';
import app from '../firebase.js'
import { getDatabase,  set } from "firebase/database";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SERVER from './URLS.js';

const storage = getStorage();
const database = getDatabase(app);
const basePathArticles = 'articles'


const useStyles = makeStyles({

 form: {
     background: 'rgba(255,255,255,1)',
     color: 'black',
     width: "80%",
     padding: '20px',
     margin: 'auto'
  },
  textarea: {
   width: '100%'
  },
  heading: {
   background: "rgba(0,0,0,0.5)",
   color: "white",
   fontSize: '22px',
   textAlign: "center"
  }
})
function ArticleForm({article}) {
  const [id, setID] = useState(null);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("Type in here please!");
  const [galleryFiles, setGalleryFiles] = useState([]);
  const [selectedFilePreviews, setSelectedFilePreviews] = useState([null]);
  const classes = useStyles();

  const [cloudURLs, setCloudURLs] = useState([])

  useEffect(()=>{
    if(article){
      setID(article._id);
      setTitle(article.title);
      setText(article.content);
      setSelectedFilePreviews(article.gallery);
      setGalleryFiles(article.gallery);
      setCloudURLs(article.gallery);
    }
  },[article])
  
 
  function handleSubmit(event) {
    event.preventDefault();
      let data = {
        _id: id,
        title: title,
        content: text,
        gallery: galleryFiles,
      }
      axios.post(SERVER + '/post_article', data).then(res=>{
        
      })
    
    
  }

  

  const onGalleryChange = (e,ind) => {
    let ngalfiles = [...galleryFiles];
    let nselectedfilepreviews = [...selectedFilePreviews];
    ngalfiles[ind] = e.target.files[0];

    let filereader = new FileReader();

    filereader.addEventListener("load", function () {
      // convert image file to base64 string
      nselectedfilepreviews[ind] = filereader.result;
      setSelectedFilePreviews(nselectedfilepreviews);


       
        let data = new FormData();
        data.append('image1', e.target.files[0], e.target.files[0].name)
        
        return axios.post(SERVER + '/upload', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res=>{
          let ncloudurls = [...cloudURLs]
          ncloudurls[ind] = res.data;
          setCloudURLs(ncloudurls);
          setGalleryFiles(ncloudurls);
        })
  
       
    }, false);

    filereader.readAsDataURL(e.target.files[0]);
     
    
    setGalleryFiles(ngalfiles);
    


  }

  const addMoreToGallery = () => {
    let ngal = [...galleryFiles];
    ngal.push(null)
    setGalleryFiles(ngal);
  }


  const handleReset = () => {
    setID(null);
      setTitle('');
      setText('');
      setGalleryFiles([]);
      setSelectedFilePreviews([]);
  }

  const removeImage = (i) => {
    let ngal = [...galleryFiles];
    ngal.splice(i,1)
    setGalleryFiles(ngal);
  }

  return (
    <div className={classes.form}>
   

     <div className={classes.heading}>Articles Admin</div>
     <TextField
      id="title"
      label="Article Title"
      value={title}
        onChange={e => setTitle(e.target.value)}
        fullWidth
      />
      <ReactQuill theme="snow" value={text} onChange={setText} />
      <Grid container align="center" justify="center" alignItems="center">
        
        
        {galleryFiles.map((gfile, indg) => {
          return (
            <Grid item xs={12} md={6}>
              <input
                accept="image/*"
                //className={classes.input}
                style={{ display: 'none' }}
                id={"raised-button-file-" + indg}
                type="file"
                onChange={(event) => onGalleryChange(event, indg)}
              />
              {gfile?.name}
              <img
                width="100%"
                //className={classes.media}
                src={gfile}
              />
              
              <label htmlFor={"raised-button-file-" + indg}>
                <Button variant="contained" component="span" className={classes.button} >
                  Upload Image
                </Button>
              </label>
              <label htmlFor={"raised-button-remfile-" + indg}>
                <Button variant="contained" component="span" className={classes.button} onClick={(event) => removeImage(indg)}>
                  DEL
                </Button>
              </label>
            </Grid>
          )
        })}

      </Grid>
      
      
    <Button onClick={addMoreToGallery} variant="outlined" > + Extra image</Button>
    <Button onClick={handleReset} variant="outlined" >Reset</Button>
     <Button onClick={handleSubmit} variant="outlined" >Submit</Button>
     
    
    </div>
  );
}

export default ArticleForm;