import React, { useState, useEffect, useRef } from 'react';


import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import VideoTile from "./VideoTile";
import Tilt from 'react-parallax-tilt';
import { Button, Grid } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { resolvePath } from 'react-router-dom';
import { Try } from '@mui/icons-material';
import { gsap } from 'gsap';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import { $, jQuery } from 'jquery'
import ReactPlayer from 'react-player';
import Tooltip from '@mui/material/Tooltip';
import AnimationCanvas from './cnv';
import ssbg from '../images/ss_bgd.jpg'
import sslogo from '../images/SweetStruggle.png'
import { fontFamily } from '@mui/system';
const storage = getStorage();
const basePathArticles = 'articles'
const articlesRef = ref(storage, basePathArticles);




const useStyles = makeStyles({

 show: {
  height: '150px',
  background: 'transparent',
  //background: 'green'
 },
 hide: {
  height: '50px',
  background: '#1d242d',
  paddingLeft: '5px',

 },
 section_heading: {
  fontSize: '90px',
  fontFamily: 'Darkline',
  color: 'white',
  //background: '#4B4E53',
  textAlign: 'center',
  //margin: '10px',
  //padding: '10px',
},
 articlesContainer: {
  backgroundImage: `url(${ssbg})`,
  backgroundSize: 'cover',
  WebkitBackgroundSize: 'cover',
  backgroundPosition: "center center",
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '40px'
 },
 videosContainer: {
  backgroundColor: '#333333',
  //backgroundSize: 'cover',
  //WebkitBackgroundSize: 'cover',
  //backgroundPosition: "center center",
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '40px',
  fontFamily: 'Abel !important'
 },
 coolTitile: {
  fontSize: '120px',
  color: "white",
  width: '100%',
  display: 'block',
  textAlign: 'center',
  fontFamily: 'Darkline'
 },

 tile_background: {
  background: 'black',
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  zIndex: 1,
  filter: 'alpha(opacity=50)',
  opacity: .5,
 },
 textContent: {
  positon: 'relative',
  bottom: 0,
  width: '80%',
  margin: 'auto',
  fontSize: '18px',
  fontWeight: '400',
  color: "white",
  fontFamily: 'Georgia, serif',
  marginBottom: '50px',
  color: '#7e9dad',
  lineHeight: '30px'
 },
 textContentVid: {
  positon: 'relative',
  bottom: 0,
  width: '80%',
  margin: 'auto',
  fontSize: '20px',
  fontWeight: '400',
  color: "white",
  fontFamily: 'Abel',
  marginBottom: '50px',
  lineHeight: '30px'
 },
 video: {
  position: "fixed",
  left: '50%', /* % of surrounding element */
  top: '50%',
  transform: 'translate(-50%, -50%)', /* % of current element */
  zIndex: -1,
 },
 galleryTiles: {
  padding: '5%',
  background: 'rgba(119, 47, 26, 0.8)',
  marginTop: '30px'
 },
 galleryHolder: {
  display: 'block',
  position: 'fixed',
  top: 0,
  bottom: 0,
  width: '100%',
  textAlign: 'center',
  background: 'rgba(77, 47, 71, 0.95)',
  zIndex: 10001,
 },
 close: {
  textAlign: 'right',
  color: 'white',
  fontSize: '30px',
  cursor: 'pointer',
  height: '50px',
  position: 'relative',

  padding: '5px',
 },
 imageTile: {
  width: '100%',
  height: 'auto',
 },
 sectionContainer: {
  marginBottom: '40px',
 },
 video_tile: {
  background: 'black',
  padding: '5px',
  height: "100%",
  cursor: "pointer",
 },
 image_thumb: {
  width: '100%', 
  height: 'auto', 
  bottom: 0, 
  position: 'relative', 
  display: 'inline-block'
 },
 fullWidthVideo: {
  color: 'white',
  backgroundColor: "rgba(33,33,33,.9)",
  zIndex: 10000,
  position: 'fixed',
  bottom: 0,
  top: 0,
  width: '100vw', /* viewport width */
  height: '100vh', /* viewport height */

  justifyContent: 'center',
  overflowY: 'scroll',
  overflowX: 'hidden',
 },
 fullVideoContainer: {
   width: '80%',
   height: '80%',
   padding: '3%',
   margin: 'auto',
 },
 videoHeader: {
  color: '#6f1e9c',
  textAlign: 'right',
  height: '40px',
  padding: '10px',
},
ss_logo: {
  width: '100%',
  textAlign: 'center',
  marginBottom: '50px'
},
ss_img: {
  height: 'auto',
  marginTop: '50px',
  width: '60%',
  maxWidth: '800px',
  marginBottom: '50px'
},
letvichka: {
  width: '40%',
  margin: 'auto',
  borderTop: '17px solid #420240'
}

})


export default function Videos(props) {
 const classes = useStyles();
 const [videos, setVideos] = useState([])
 const [activeArticle, setActiveArticle] = useState(null);
 const [windowHeight, setWindowHeight] = useState(0);
 const [videosCount, setVideosCount] = useState(8);
 const [nextPageID, setNextPageId] = useState(null);
 const [uploadsId, setUplodsId] = useState(null);
 const [activeVideo, setActiveVideo] = useState(null)

 const step = 4;



 useEffect(() => {
  setWindowHeight(window.innerHeight);
 })




 useEffect(() => {
  const call = axios.create({
   baseURL: 'https://www.googleapis.com/youtube/v3/',
   params: {
    part: 'contentDetails',
    id: 'UCRi5yYaOQecmj6eDZn_I3jw',
    key: 'AIzaSyB5r8vtsFjs6ESE_TFUXLpzoCOY3OEJ0J8'
   }
  })
  call.get('/channels').then((res) => { setUplodsId(res.data.items[0].contentDetails.relatedPlaylists.uploads) })
 }, [])

 const fetchVideos = () => {

  const call = axios.create({
   baseURL: 'https://www.googleapis.com/youtube/v3/',
   params: {
    part: 'snippet',
    playlistId: uploadsId,
    key: 'AIzaSyB5r8vtsFjs6ESE_TFUXLpzoCOY3OEJ0J8',
    maxResults: videosCount,
    pageToken: nextPageID
   }
  })
  call.get('/playlistItems').then((res) => { setVideos(videos.concat(res.data.items)); setNextPageId(res.data.nextPageToken) })

 }


 const loadMoreVideos = () => {
  fetchVideos()
 }

 useEffect(() => {
  const call = axios.create({
   baseURL: 'https://www.googleapis.com/youtube/v3/',
   params: {
    part: 'snippet',
    playlistId: uploadsId,
    key: 'AIzaSyB5r8vtsFjs6ESE_TFUXLpzoCOY3OEJ0J8',
    maxResults: videosCount,
    pageToken: nextPageID
   }
  })
  call.get('/playlistItems').then((res) => {  setVideos(res.data.items); setNextPageId(res.data.nextPageToken) })
 }, [uploadsId])


 const bigVideo = useRef();

 useEffect(()=>{
  if(activeVideo!==null){
      gsap.fromTo(bigVideo.current, {y:'100%'}, {duration: 1, y: 0})
  }else{
      gsap.fromTo(bigVideo.current, {y:0}, {duration: 1, y: '100%'})
  }
  
},[activeVideo])


 return (

 

<div>
      <Helmet>
       <title>Evgeny Genchev - Videos</title>
       <meta name="description" content="" />
       <script src="https://apis.google.com/js/platform.js"></script>
     </Helmet>
   <div className={classes.sectionContainer}>
   
  

    <div className={classes.articlesContainer}>
      <div className={classes.ss_logo}><img src={sslogo} className={classes.ss_img}/><br/>
      <div class="g-ytsubscribe" data-channelid="UCRi5yYaOQecmj6eDZn_I3jw" data-layout="full" data-theme="dark" data-count="default" style={{width: '100%', textAlign: 'center !important', marginTop: '20px !important'}}></div>
      </div>
     <div className={classes.textContent}>
      <p>My video series on YouTube is called “Sweet Struggle”. Make sure you subscribe to the channel so you get notified when videos come out!</p>
      
      <p>I have been preaching this mantra for a long time so I thought it would be only fair that I name the series after it. There are many life
       experiences that have solidified its part in my life, but for now I will share with you the fundamental idea behind it.</p>

      <p>Life, for me, is a constant pursuit of expanding one‘s horizon – of skill, knowledge, experience, consciousness… As a result,
       I frequently put myself through “personal-development” projects to become better in certain aspects. It comes down to the realisation
       that most times in life, in order to become effortless in anything, you first need to be very consciously effortful. And this process
       normally causes some discomfort. It might be a little painful. It’s a STRUGGLE! But what allows me to keep going, is the love, passion
       and devotion I have for what I do. The idea that wakes me up at 3am… The vision that keeps me working through the night… That SWEET feeling
       of progress in something that is meaningful and significant to me.</p>

      <p>In my life, this has always been music. I have studied it since I can remember, I have travelled the world through it, I have made
       truthful connections with it. It is a fundamental part of my fabric and I identify myself with it.</p>

      <p>Growing up, although I was actively seeking, it was often challenging to find the right path that would help me move in a good direction.
       This pushed me to be more observant and analytical, to look for the cause and effect, and not be afraid to challenge the status quo. I have been
       fortunate to acquire a lot of knowledge and experience through my travels and studies, and developed a unique toolbox that has enabled me to tackle
       any issues that have stood in my way.</p>

      <p>I realise that not everybody has been as fortunate as me. So in this series, I would like to share all that I know with you. I believe in
       people’s right to strive to become better and develop, and I would like to enable you to fulfil your potential and have a smoother way on the path of Sweet Struggle.</p>
     </div>
    </div>
   </div>

    {activeVideo && 
       <div className={classes.fullWidthVideo} ref={bigVideo}>
         <div className={classes.videoHeader}>
           <Button variant="contained" onClick={() => setActiveVideo(null)}><CloseIcon /></Button>
         </div>
         <div className={classes.fullVideoContainer}> 
           <ReactPlayer url={`https://www.youtube.com/watch?v=${activeVideo}`} width="100%" height="100%" controls={true}/>
        </div>
      

  </div>
    }
   

   <div className={classes.sectionContainer}>
  
    <div className={classes.videosContainer}>
    <div className={classes.section_heading}>
    <div style={{width: '100%', height: '5px'}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
                    Latest Videos</div>
     <div className={classes.textContentVid}>
      <Grid container spacing={2}>
      {videos.map((v,ind) => {
         
       return (
        <Grid item xs={12} md={6} lg={3} key={v.snippet.resourceId.videoId}>
         <VideoTile snippet={v.snippet} onOpenVideo={setActiveVideo}/>
        </Grid>
       )
      })}
      </Grid>
      <Button variant='contained' onClick={loadMoreVideos}>Load more</Button>
     </div>

    </div>
   </div>
 </div>
 );
}