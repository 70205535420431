import React, { useState, useEffect, useRef, useMemo, Suspense, useCallback } from 'react';
import { Box, Plane } from "@react-three/drei";
import { Canvas, extend, useFrame, useLoader, useThree } from 'react-three-fiber';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextureLoader, Raycaster } from 'three';

extend({ OrbitControls })


function CameraControls() {
 const controlsRef = useRef()
 const {
  camera,
  gl: { domElement }
 } = useThree()
 useFrame(() => {controlsRef.current.update(); controlsRef.current.enableZoom = false;})
 return (
  <orbitControls
   ref={controlsRef}
   args={[camera, domElement]}
   autoRotate
   autoRotateSpeed={-10}
  />
 )
}
function ImageBox({ img }) {
 const [colorMap, specularMap] = useLoader(TextureLoader, [img, img]);
 const boxRef = useRef();
 useFrame(() => {
  boxRef.current.rotation.y += 0.004;
  boxRef.current.rotation.x += 0.004;
  boxRef.current.rotation.z += 0.004;
 });
 return (
  <>

   <CameraControls />
   <group>




    <Box castShadow receiveShadow ref={boxRef} position={[0, 0, 0]} scale={1.5}>
     <meshStandardMaterial map={colorMap} opacity={0.9} transparent/>
    </Box>

   </group>
  </>
 )
}

function AnimationCanvasBox({ img, height, id }) {

   return (
      <>
         {img &&
            <Canvas
               colorManagement
               shadowMap
               camera={{ position: [0, 1.5, 2], fov: 60 }}
               style={{ width: '100%', height: `${height}px` }}
               id={id}
            >
               <ambientLight intensity={0.2} />
               <directionalLight
                  intensity={0.5}
                  castShadow
                  shadow-mapSize-height={250}
                  shadow-mapSize-width={250}
               />
               <pointLight color="white" intensity={1} position={[-5, 5, 2]} />

               <Suspense fallback={<></>}>
                  <ImageBox img={img} />
               </Suspense>
            </Canvas>
         }
      </>
   )
}


export default AnimationCanvasBox;
