import React, { useState, useEffect, useRef } from 'react';


import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button, Grid } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { resolvePath } from 'react-router-dom';
import { Try } from '@mui/icons-material';
import { gsap } from 'gsap';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet';
import { $, jQuery } from 'jquery'
import ReactPlayer from 'react-player';
import Tooltip from '@mui/material/Tooltip';
const storage = getStorage();
const basePathArticles = 'articles'
const articlesRef = ref(storage, basePathArticles);
const useStyles = makeStyles({

    show: {
     height: '150px',
     background: 'transparent',
     //background: 'green'
    },
    hide: {
     height: '50px',
     background: '#1d242d',
     paddingLeft: '5px',
   
    },
    section_heading: {
     opacity: 0.8,
     fontSize: '24px',
     fontFamily: 'Abel',
     color: 'white',
     background: '#4B4E53',
     textAlign: 'center',
     margin: '10px',
     padding: '10px',
   },
     
   
    tile_background: {
     background: 'black',
     position: 'absolute',
     top: '0px',
     left: '0px',
     width: '100%',
     height: '100%',
     zIndex: 1,
     filter: 'alpha(opacity=50)',
     opacity: .5,
    },
    textContent: {
     positon: 'relative',
     bottom: 0,
     width: '80%',
     fontSize: '24px',
     color: "white",
     fontFamily: 'Abel'
    },
    video: {
     position: "fixed",
     left: '50%', /* % of surrounding element */
     top: '50%',
     transform: 'translate(-50%, -50%)', /* % of current element */
     zIndex: -1,
    },
    galleryTiles: {
     padding: '5%',
     background: 'rgba(119, 47, 26, 0.8)',
     marginTop: '30px'
    },
    galleryHolder: {
     display: 'block',
     position: 'fixed',
     top: 0,
     bottom: 0,
     width: '100%',
     textAlign: 'center',
     background: 'rgba(77, 47, 71, 0.95)',
     zIndex: 10001,
    },
    close: {
     textAlign: 'right',
     color: 'white',
     fontSize: '30px',
     cursor: 'pointer',
     height: '50px',
     position: 'relative',
   
     padding: '5px',
    },
    imageTile: {
     width: '100%',
     height: 'auto',
    },
    sectionContainer: {
     marginBottom: '40px'
    },
    video_tile: {
     background: 'rgba(0,0,0,0.5)',
     padding: '5px',
     height: "auto",
     overflow: 'hidden',
     cursor: "pointer",
    },
    image_thumb: {
     width: '100%', 
     height: 'auto', 
     bottom: 0, 
     position: 'relative', 
     display: 'inline-block'
    },
    fullWidthVideo: {
     color: 'white',
     backgroundColor: "rgba(33,33,33,.9)",
     zIndex: 10000,
     position: 'fixed',
     bottom: 0,
     top: 0,
     width: '100vw', /* viewport width */
     height: '100vh', /* viewport height */
   
     justifyContent: 'center',
     overflowY: 'scroll',
     overflowX: 'hidden',
    },
    fullVideoContainer: {
      width: '80%',
      height: '80%',
      padding: '3%',
      margin: 'auto',
    },

   slide: {
       float: 'left'
   },
   vid_title: {
    height: "75px"
   }
   
   })
export default function VideoTile({snippet, onOpenVideo}) {
const classes = useStyles();
var slide1 = useRef(null);

var animation = useRef(null);

useEffect(()=>{
    animation.current = gsap.timeline().fromTo(slide1.current, {scaleX: 0.3, scaleY: 0.3}, {scaleX: 0.8,scaleY: 0.8, duration: .5});
    animation.current.play();
    return () => {
        animation.current.kill()
        animation = null; 
    }
},[])

const onHover = () => {

    animation.current = gsap.timeline().fromTo(slide1.current, {scaleX: 0.8, scaleY: 0.8}, {scaleX: 1, scaleY: 1, duration: .5});
    animation.current.play()
}

const killAnimation = () => {
    animation.current.reverse()
    animation.current = null;
    //slide1.current = null;
}

return(

        <Tooltip title="Click to open the video" placement="top">
          <div className={classes.video_tile} onClick={()=>onOpenVideo(snippet.resourceId.videoId)} >
           <div ref={slide1} className={classes.slide} onMouseOver={onHover} onMouseOut={killAnimation}>
            <div className={classes.vid_title}>{snippet.title}</div>
            <img src={snippet.thumbnails.high.url} className={classes.image_thumb}/>
           </div>
          </div>
         </Tooltip>
)


}