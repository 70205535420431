import { Tooltip } from "@mui/material";
import Tilt from 'react-parallax-tilt';
import { makeStyles } from '@mui/styles';
import ReactTooltip from 'react-tooltip';
const useStyles = makeStyles({
 footer: {
     background: '#0f1116',
     color: 'white',
     marginTop: '20px'
 },
 social_links: {
     overflow: 'hidden',
     height: 'auto',
     background: '#1b232b',
     color: "white",
     width: '100%',
     float: 'left',
     display: 'flex'
 },
 social_link: {

     display: 'inline-block',
     padding: '6px',
     //float: "right",
     //background: "rgba(102,0,166,.6)",
     borderRadius: '10px',
     margin: '1px',
     cursor: 'pointer'
 },
 botomMenu: {
     //height: '100px',
     position: 'relative',
     float: "right",
     margin: '10px',
     //transform: "translate(150px, -100px)",
     top: 0,
     right: 0
 },
 credits: {
     textAlign: 'center',     
     padding: '20px',
     background: 'black',
     fontFamily: 'Abel'
 },
 link: {
     //background: 'rgba(0,0,0,0.9)',
     color: 'white',
     //display: 'block',
     width: '150px',
     display: 'inline-block',    
     float: 'left',
   
     textDecoration: 'none',
     cursor: 'pointer',
     textAlign: 'center',
     margin: '5px',
     fontFamily: "Abel",
     cursor: 'pointer',
     //padding: '5px',
     '&:hover':{
         color: "yellow"
     }
  },     
})

const SocialLink = ({img, link, text, action}) => {
 const classes = useStyles();

 const openLink = () => {
    if(!action){
        window.open(link, '_blank');
    }else{
        
        action();
    }
     
 }

 return(
   
         <div onClick={openLink} className={classes.social_link}>
            <ReactTooltip />
            
                 <img src={img}  style={{width: 25, height: 25}} data-tip={text}/>
             
         </div>
     

 )
}

export default SocialLink