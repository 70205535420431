import React, { useState, useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { gsap } from 'gsap';
import { FitToViewport } from 'react-fit-to-viewport';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { withStyles } from "@mui/styles";
import { resolvePath, useParams, Routes, Route, Link, NavLink } from 'react-router-dom';


const StyledCard = withStyles({
    root: {
      fontFamily: 'Abel !important',
      background: "rgba(0,0,0,0) !important"
    },
   
  })(Card);

const useStyles = makeStyles({

    tile: {
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        WebkitTransition: 'all 2s ease-out',
        transition: 'all 2s ease-out',
        opacity: 1,
        position: "relative",
        //padding: '5%',
        height: 'auto',
        cursor: 'pointer'
    },
    hline: {
        borderBottom: '1px solid #a0d8c3',
        height: '50%'
    },
    tile_loading: {
        fill: '#8048a5',
        textAlign: 'center',
        margin: "auto",
        float: 'left'
    },
    tile_ready: {
        color: 'white',
        textAlign: 'center',

    },
    thumb: {
        width: '100%',
        height: 'udnefined',

    },
    card_content: {
        background: 'rgba(70, 70, 70, 0)',
        color: "white",
        textDecoration: "none !important",
        fontFamily: "Montserrat",
        fontSize: '17px !important',
        letterSpacing: '2px',
        fontWeight: '700'
    },
    card_actions: {
        background: 'rgba(0, 0, 0, 1)',
        color: "white"
    },
    card: {
        background: 'rgba(0, 0, 0, 1)',
        color: "white"
    },
    publish_date: {
        width: '100%',
        textAlign: 'right',
        marginTop: "10px"
    },
    excerpt: {
        textAlign: 'left'
    },
    btn: {
        background: '#930af5 !important',
        color: "white",
        textDecoration: 'none !important'
    },
    btn_more: {
        background: '#930af5 !important',
        textDecoration: 'none !important',
        color: "white !important"
    },
    link: {
        textDecoration: "none !important"
    }
     




})

export default function ArticleTile(props) {
    const { _id, title, content, gallery } = props.article;

    const classes = useStyles();

    return (

<>
        {title && 
            <div className={`${classes.tile} ${classes.tile_ready}`} >


            <Link to={`/articles/${encodeURIComponent(_id)}`} style={{ textDecoration: 'none' }}>
            <StyledCard sx={{ width: '100%' }} className={classes.card}>
                <CardMedia
                    component="img"
                    height={props.double?"600":"300"}
                    image={gallery[0]}

                />
                <CardContent className={classes.card_content}>
                    <Grid container>
                        <Grid item xs={3}>
                            <div className={classes.hline}></div>
                        </Grid>
                        <Grid item xs={6}>
                             
                                {title}
                             
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.hline}></div>
                        </Grid>
                    </Grid>
                    
 
                </CardContent>
                
            </StyledCard>
            </Link>
            

        </div>
        }
        
        </>


    );
}