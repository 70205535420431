import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({

    tile: {
        fontFamily: 'Abel',
        fontWeight: 'bold',
        WebkitTransition: 'all 2s ease-out',
        textAlign: 'center',
        transition: 'all 2s ease-out',
        border: '1px solid white',
        borderColor: 'rgb(96, 125, 138)',
        position: "relative",
        //padding: '5%',
        //height: 'auto',
        //width: '100%',
        //cursor: 'pointer',
        color: 'white',
        textAlign: 'center',
        position: 'relative',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '100%',
        paddingTop: '15px'

    },
    tile_date: {
        color: '#96adab',
        fontFamily: "Montserrat",
        fontSize: '16px',
        fontWeight: '600'
    },
    tile_location: {
        fontSize: '30px',
        color: '#ffffff',
        fontFamily: 'Barlow Condensed',
        fontWeight: '600',
    },
    tile_venue: {
        fontSize: '15px',
        fontWeight: '600',
        color: '#96adab',
        fontFamily: "Montserrat"
    },
    tile_url: {
        color: '#3b90ff',
        cursor: "pointer",
        display: 'inline-block',
        background: '#607d8b',
        color: 'white',
        padding: '5px',
        minWidth: '50%'
    },
    thumb: {
        width: '70%',
        height: 'auto'
    },




})

export default function TourTile(props) {
    const { date, location, venue, url } = props.event;

    const classes = useStyles();

    const openLink = () => {
        if(url){
            window.open(url, '_blank');
        }
    }

    return (

        <>
            <div className={`${classes.tile}`}>
         
                    <h2 className={classes.tile_date}>{date}</h2>
                    <h2 className={classes.tile_location}>{location}</h2>
                    <h2 className={classes.tile_venue}>{venue}</h2>
                    {url && 
                        <span className={classes.tile_url} onClick={openLink}>TICKETS</span>
                    }
            
            </div>
        </>

    );
}