import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import TourTile from './TourTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button } from '@mui/material';
import { firestore, auth } from '../firebase.js'
import { Fade } from "react-awesome-reveal";

import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { resolvePath } from 'react-router-dom';
import { Try } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';

import plovdiv_banner from '../images/zobf_960_360.jpg'

import SERVER from './URLS';

const useStyles = makeStyles({
    appbar: {

        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        paddingLeft: '40px'

    },

    show: {
        height: '150px',
        background: 'transparent',
        //background: 'green'
    },
    hide: {
        height: '50px',
        background: '#1d242d',
        paddingLeft: '5px',

    },
    datesContainer: {
        /*background: "#17191e",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",*/
        background: 'rgba(37,43,51, 1)',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        display: '-webkit-box',
        display: '-moz-box',
        display: '-ms-flexbox',
        display: '-moz-flex',
        display: '-webkit-flex',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: '70px',
    },
    coolTitile: {
        fontSize: '120px',
        fontFamily: 'Darkline',
        color: "white",
        width: '100%',
        display: 'block',
        textAlign: 'center'
    },
    article: {
  
        //display: 'inline-block',
        background: 'rgba(0,0,0, 0)',
        position: 'relative',
        width: '350px',
        height: '200px',
        margin: '1em',
        textAlign: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'center',

    },
    tile_background: {
        background: 'black',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        filter: 'alpha(opacity=50)',
        opacity: .5,
    },
    articleBig: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        zIndex: 10000,
        background: 'rgba(0,0,0,.9)',
        textAlign: 'center',
        width: '100%'
    },
    articleContent: {
        padding: '5%',
        width: '80%',
        display: 'inline-block',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
        color: 'yellow',
        overflowY: 'scroll',

    },
    articleHeader: {
        color: 'white',
        background: 'black',
        textAlign: 'right'
    },
    section_heading: {
     opacity: 0.8,
     fontSize: '120px',
     fontFamily: 'Darkline',
     color: 'white',
     textAlign: 'center',
     margin: '10px',
     padding: '10px',
     width: '100%'
    }


})




export default function TourDates(props) {
    const classes = useStyles();
    const [dates, setTourDates] = useState([]);
    const [loading, setLoading] = useState(true);

 
    useEffect(() => {
        axios.get(SERVER + '/get_tour_dates').then(res => {
            let filtered = res.data.dates.filter(a => !a.date.includes('2021'))
            setTourDates(filtered);
            setLoading(false);
        })
    }, [])

    return (

        <>
            
            {loading && 
            <LinearProgress/>
        }

            <div className={classes.sectionContainer}>

                <Parallax strength={500} style={{zIndex: '+2 !important'}}>

                    <div className={classes.datesContainer} id="tour">
                        <div className={classes.section_heading}>
                            Tour
                        </div>
                        
                        
                        {dates.map(a => {
                            
                            return (
                                <Fade duration={2000}>
                                    
                                    <div className={classes.article}>
                                         
                                            <TourTile event={{...a}} />
                                        
                                    </div>
                                </Fade>
                            )
                        })}


                    </div>
                </Parallax>
                
            </div>
        </>
    );
}