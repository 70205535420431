import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { getStorage, listAll, getDownloadURL, uploadBytes, uploadString, ref, getMetadata } from "firebase/storage";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Grid } from '@mui/material';
import { fontSize } from '@mui/system';
import app from '../firebase.js'
import { getDatabase,  set } from "firebase/database";
import 'react-quill/dist/quill.snow.css';
import SERVER from './URLS.js';
import ReactQuill, { Quill } from "react-quill";

// #1 import quill-image-uploader
import ImageUploader from "quill-image-uploader";
import ImageResize from 'quill-image-resize-module-react';
import 'react-quill/dist/quill.snow.css';
// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
Quill.register('modules/imageResize', ImageResize);


const storage = getStorage();
const database = getDatabase(app);

const basePathArticles = 'articles'


const useStyles = makeStyles({

 form: {
     background: 'rgba(255,255,255,1)',
     color: 'black',
     width: "80%",
     padding: '20px',
     margin: 'auto'
  },
  textarea: {
   width: '100%'
  },
  heading: {
   background: "rgba(0,0,0,0.5)",
   color: "white",
   fontSize: '22px',
   textAlign: "center"
  },
  quillHolder: {
    color: 'black'
  }
})


export default function MailTemplateForm(){

  const [text, setText] = useState('');
  const [subject, setSubject] = useState('')
  const classes = useStyles()
  const quillRef = useRef(null);

  const handleChange = (html) => {
    
  }

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      [{ 'color': [] }, { 'background': [] }],   
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    },
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {

          let data = new FormData();
          data.append('image1', file, file.name)
          
          return axios.post(SERVER + '/upload', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then(res=>{resolve(res.data)})
      })
    }
    }
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'color',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    "imageBlot"
  ];

const handleSend = () => {

  let data = {
    content: quillRef.current.value,
    subject: subject,
  }
  axios.post(SERVER + '/send_mass_mail', data).then(res=>{
    
  })
}


    return (
      <div className={classes.quillHolder}>
        <TextField
              id="subject"
              label="Subject"
              maxRows={1}
              value={subject}
              onChange={(e)=>{setSubject(e.target.value)}}
              fullWidth
              variant="filled" 
            />
      <ReactQuill
      //theme="snow"
      onChange={handleChange}
      value={text}
      modules={modules}
      formats={formats}
      bounds={'#root'}
      ref={quillRef}
      >
        <div className="my-editing-area" />
        </ReactQuill>
        <Button onClick={handleSend}>Send</Button>
      </div>
      
    );
  
}

