import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import logo from '../images/EG_logo.png';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import ArticleTile from './ArticleTile';
import backsvg from '../images/background.svg';
import { Parallax } from 'react-parallax';
import background from "../images/background.svg";
import Tilt from 'react-parallax-tilt';
import { Button } from '@mui/material';
import { Fade } from "react-awesome-reveal";
import { gsap } from 'gsap';

import { keyframes } from '@mui/system';
import {  Outlet } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import SERVER from './URLS';

const basePathArticles = 'articles'


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const useStyles = makeStyles({
    appbar: {

        position: 'fixed',
        zIndex: '99',
        float: 'left',
        width: '100%',
        opacity: 1,
        paddingLeft: '40px'

    },

    show: {
        height: '150px',
        background: 'transparent',
        //background: 'green'
    },
    hide: {
        height: '50px',
        background: '#1d242d',
        paddingLeft: '5px',

    },
    articlesContainer: {
        /*background: "#17191e",
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover',
        backgroundPosition: "center center",*/
        background: "rgb(29, 36, 45, .8)",
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'stretch',
        display: 'block',

        paddingBottom: '60px',
        paddingLeft: '60px',
        paddingRight: '60px',
    },
    coolTitile: {
        fontSize: '120px',
        fontFamily: 'Darkline',
        color: "white",
        width: '100%',
        display: 'block',
        textAlign: 'center'
    },
    article: {

        //display: 'block',
        background: 'rgba(0,0,0, .5)',
        //position: 'relative',
        width: '100%',

        textAlign: 'center',

        //float: 'left',

    },
    tile_background: {
        background: 'black',
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: 1,
        filter: 'alpha(opacity=50)',
        opacity: .5,
    },
    articleBig: {
        color: 'white',
        backgroundColor: "rgba(33,33,33,.9)",
        zIndex: 10000,
        position: 'fixed',
        bottom: 0,
        top: 0,
        width: '100vw', /* viewport width */
        height: '100vh', /* viewport height */

        justifyContent: 'center',
        overflowY: 'scroll',
        overflowX: 'hidden',
    },

    articleHeader: {
        color: '#6f1e9c',
        textAlign: 'right',
        height: '40px',
        padding: '10px',
    },
    section_heading: {
     fontSize: '60px',
     fontFamily: 'Darkline',
     color: 'white',
     //background: '#4B4E53',
     textAlign: 'center',
     //margin: '10px',
     //padding: '10px',
    },
    article_inner: {
        background: '#3f3f3f',
        color: '#95A5A6',
        fontSize: '20px',
        padding: '3%',
        width: '95%',
        textAlign: 'justify',
        whiteSpace: 'pre-line',
        marginLeft: 'auto',
        marginRight: 'auto',
        "&:-webkit-scrollbar": {
            display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none'
    }


})



export default function AllArticles(props) {
    const [show, setShow] = useState(true);
    const classes = useStyles();
    const [articles, setArticles] = useState([])
    const [windowHeight, setWindowHeight] = useState(0);
    const [dates, setDates] = useState([]);
    const [activeArticle, setActiveArticle] = useState(null);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setWindowHeight(window.innerHeight);
    })

    useEffect(()=>{
        //load articles on load
        axios.get(SERVER + '/get_articles').then(res=>{

            setArticles(res.data.articles);
            setLoading(false);
        })
      },[])

    const bigArticle = useRef();

    useEffect(() => {
        if (activeArticle !== null) {
            gsap.fromTo(bigArticle.current, { y: '100%' }, { duration: 1, y: 0 })
        } else {
            gsap.fromTo(bigArticle.current, { y: 0 }, { duration: 1, y: '100%' })
        }

    }, [activeArticle])

    return (
        <>
        {loading && 
            <LinearProgress/>
        }
            <div className={classes.sectionContainer}>
                
                <div className={classes.articlesContainer}>
                    <div className={classes.section_heading}>Latest Posts</div>
                    {articles.length > 0 && 
                        <Grid container spacing={5} align="center" justify="top" alignItems="top">

                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <div className={classes.article} key={`article_internal_${0}`}>
                                    <ArticleTile article={{ ...articles[0] }} date={dates[0]?.timeCreated} onSet={setActiveArticle} double/>
                                </div>
                            </Grid>

                            <Grid item xl={4} md={4} sm={12} xs={12}>
                                <Grid container spacing={5} align="center" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <div className={classes.article} key={`article_internal_${1}`}>
                                            <ArticleTile article={{ ...articles[1] }} date={dates[1]?.timeCreated} onSet={setActiveArticle} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.article} key={`article_internal_${2}`}>
                                            <ArticleTile article={{ ...articles[2] }} date={dates[2]?.timeCreated} onSet={setActiveArticle} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xl={4} md={4} sm={12} xs={12} key={`article_container_${3}`}>
                                <div className={classes.article} key={`article_internal_${3}`}>
                                    <ArticleTile article={{ ...articles[3] }} date={dates[3]?.timeCreated} onSet={setActiveArticle} double/>
                                </div>
                            </Grid>
                            <Grid item xl={12} key={`article_container_all`}>
                                Check All
                            </Grid>
                        </Grid>
                    }
                    
                </div>
                <Outlet/>
            </div>
            
        </>
    );
}