import * as React from 'react';


import instagram_logo from '../images/social_icons/instagram.png';
import facebook_logo from '../images/social_icons/facebook.png';
import twitter_logo from '../images/social_icons/twitter.png';
import medium_logo from '../images/social_icons/medium.png';
import youtube_logo from '../images/social_icons/youtube.png';
import spotify_logo from '../images/social_icons/spotify.png';
import apple_logo from '../images/social_icons/apple.png';
import patreon_logo from '../images/social_icons/patreon.png';
import subscribe_logo from '../images/social_icons/bell.png';

import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import {
    Link,
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import Tilt from 'react-parallax-tilt';
import "./fsmenu.css";
import { gsap, } from 'gsap';
import SocialLink from './SocialLink';







const links = [
    {   
        img: instagram_logo,
        link: 'https://www.instagram.com/evgenygenchev/',
        text: "Instagram",
    },
    {   
        img: facebook_logo,
        link: 'https://www.facebook.com/EvgenyGenchev',
        text: "Facebook",
    },
    {   
        img: twitter_logo,
        link: 'https://twitter.com/Evgeny_Genchev',
        text: "Twitter",
    },
    {   
        img: medium_logo,
        link: 'https://medium.com/@evgenygenchev',
        text: "Medium",
    },
    {   
        img: youtube_logo,
        link: 'https://www.youtube.com/evgenygenchev',
        text: "Youtube",
    },
    {   
        img: spotify_logo,
        link: 'https://open.spotify.com/artist/6n7ZUZfIHRIJU23LuIzTlx?si=BCoLK97gQ7Ge2B1xoBx62w&nd=1',
        text: "Spotify",
    },
    {   
        img: apple_logo,
        link: 'https://music.apple.com/us/artist/evgeny-genchev/1546386935',
        text: "Apple Music",
    },
    {   
        img: patreon_logo,
        link: 'https://www.patreon.com/evgenygenchev/posts',
        text: "Patreon",
    },
    {   
        img: subscribe_logo,
        link: 'SUBSCRIBE',
        text: "SUBSCRIBE",
        
    },
]


const useStyles = makeStyles({

    link: {

        color: 'white',
        //display: 'block',
        width: '100%',
        height: '100%',

        float: 'left',

        textDecoration: 'none',
        cursor: 'pointer',
        background: 'rgba(0,0,0,0.6)',
        textAlign: 'center',
        margin: '5px',
        fontFamily: "Abel",
        position: 'absolute',
        top: '50%',
        fontSize: '20px',

        transform: 'translateY(-50%)',

    },

    cover: {
        color: 'white',
        height: `100vh`,
        width: `20rem`,
        backgroundColor: "rgba(33,33,33,1)",
        zIndex: 10000,
        transform: `translateX(1000%)`,
        transition: '0.3s',
        position: 'fixed',
        right: 0,
        top: 0,
        //display: 'flex',
        justifyContent: 'center',


    },
    cover_opened: {
        transform: 'translateX(0%)'
    },

    box: {
        position: 'relative',
        width: '200px',
        height: '50px',
        float: 'left',
        margin: '10px',
        marginLeft: "20px",
        fontFamily: "Abel"
    },
    buttonBackground: {
        background: '#636564',
        position: 'fixed',
        opacity: 0.5,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        boxShadow: '5px 5px 10px rgb(0, 0, 0)',
        width: '0px'
    },
    closeSpan: {
        background: 'black',
        width: "75px",
        height: "75px",
        
        float: "right"
    }


})

const Tile = ({ text, to }) => {
    const classes = useStyles();
    var tl = React.useRef(null);
    var tl2 = React.useRef(null);
    var txt = React.useRef(null);
    var bck = React.useRef(null);



    React.useEffect(() => {

        tl.current = gsap.timeline().fromTo(
            txt.current,
            { opacity: 0, x: -100 },
            { opacity: 1, x: 0, delay: 0.5, duration: 0.5 }
        )
        tl.current.play();


        return () => {
            if(tl.current !== null){
                tl.current.kill()
                //tl=null;
            }
            
        }

    })

    const onHover = () => {
        tl2.current = gsap.timeline().fromTo(
            bck.current,
            { width: '0%', },
            { width: '100%', delay: 0, duration: 0.5 }
        )
        tl2.current.play();

    }

    const killEffect = () => {
        
            tl2.current.reverse();

    }

    


    return (

        <div className={classes.box} onMouseEnter={onHover} onMouseLeave={killEffect}>

            <Link to={to} className={classes.link}>

                <div ref={bck} className={classes.buttonBackground}> </div>
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translateY(-50%) translateX(-50%)',
                }} >
                    <div ref={txt}>{text}</div>
                </div>
            </Link>
        </div>

    )
}

export default function FullScreenMenu({onSubscribe, onclose, open}) {

    const classes = useStyles();

    

    return (
        <>


            <div className={`${classes.cover} ${open == true ? classes.cover_opened : null}`} onClick={() => onclose()}>

                <div style={{ textAlign: 'right', width: '100%', float: 'right', color: 'white', cursor: 'pointer',}}>
                    <div className={classes.closeSpan}><CloseIcon onClick={() => onclose()} style={{transform: 'scale(3) translate(-8px, 8px)'}}/></div>
                </div>

                <Grid container>
                    <Grid item xs={10}>
                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="Home" to='/home' />

                            </Tilt>
                        </div>

                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="About" to='/about' />

                            </Tilt>
                        </div>

                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="Videos" to='/videos' />

                            </Tilt>
                        </div>
                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="Music" to='/music' />

                            </Tilt>
                        </div>
                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="Festival" to='/ssf' />

                            </Tilt>
                        </div>
                        <div className={classes.box}>
                            <Tilt>

                                <Tile text="Contact" to='/contact' />

                            </Tilt>
                        </div>

                        {false &&
                            <div className={classes.box}>
                                <Tilt>

                                    <Tile text="Admin" to='/admin' />

                                </Tilt>
                            </div>
                        }
                        
                        
                        
                        

                        
                    </Grid>
                    <Grid item xs={2}>
                    {links.map((l)=>{
                                return(
                                    
                                    <SocialLink img={l.img} link={l.link} text={l.text} action={l.text=="SUBSCRIBE"?onSubscribe:null}/>
                                   
                                )
                            })}
                    </Grid>
                </Grid>


            </div>


        </>


    );
}
