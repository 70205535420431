import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { useParams  } from 'react-router-dom';
import { Helmet } from "react-helmet";


import eg_cosial_icon from '../images/evve_face.png';
import fah_cosial_icon from '../images/farah_face.png';

import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { IconButton } from '@mui/material';
import SERVER from './URLS';



import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { useOutletContext } from "react-router-dom";


const useStyles = makeStyles({


  article: {
    background: 'rgba(0,0,0, .5)',
    width: '100%',
    textAlign: 'center',
  },

  articleBig: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,1)",
    
    //position: 'fixed',
    //bottom: 0,

    justifyContent: 'center',

  },

  articleHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },

  article_inner: {
    fontFamily: 'Abel',
    background: 'rgba(33,33,33,1)',
    color: '#95A5A6',
    fontSize: '20px',
    padding: '10px',
    width: '90%',
    height: "100%",
    textAlign: 'justify',
    whiteSpace: 'pre-line',
    marginLeft: 'auto',
    marginRight: 'auto',
    "&:-webkit-scrollbar": {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none'
  },
  section_heading: {
    fontSize: '100px',
    fontFamily: 'Darkline',
    color: 'white',
    background: 'rgba(53, 0, 87, 0.0)',
    textAlign: 'center',

    //paddingTop: '10px',
    //paddingBottom: '10px',
    width: '100%',
    //borderRadius: '10px',
    //border: "1px solid yellow"
    //borderBottomRightRadius: "10px"
  },

  galery_div: {
    background: 'rgba(255,255,255,0.5)',
    width: "94% !important",
    padding: '3%',


  },
  galleryHolder: {
    display: 'block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    background: 'rgba(77, 47, 71, 0.95)',
    zIndex: 10001,
},

imageTile: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer'
},
footerContainer: {
  fontSize: '18px'
},
social_share: {
  fontFamily: "Abel",
  fontSize: '20px',
  height: "40px"
},
letvichka: {
  width: '40%',
  margin: 'auto',
  borderTop: '17px solid #420240'
}

})


const ArticleFooter = () => {
  const classes = useStyles()
  return(
    <>
    <hr style={{color: 'white !important'}}/>
    <div className={classes.footerContainer}>
    When music found the right expression and literature found the right tone, <b>Evgeny</b> and <b>Farahdeen</b> united to create as one.
    <Grid container spacing={5} align="center" justify="top" alignItems="top">
      <Grid item md={6} xs={12}>
        <Grid container spacing={1} align="center" justify="center" alignItems="center">
          <Grid item xl={1} md={2} xs={3}>
            <img src={eg_cosial_icon} width="50"/>
            
          </Grid>
          <Grid item xl={1} md={2} xs={3}>
         
            <IconButton aria-label="twitter" color="primary" onClick={()=> window.open("https://twitter.com/evgeny_genchev?lang=en", "_blank")}>
              <TwitterIcon/>
            </IconButton>
            <IconButton aria-label="insta" color="primary"  onClick={()=> window.open("https://www.instagram.com/evgenygenchev/", "_blank")}>
              <InstagramIcon/>
            </IconButton>
            
          </Grid>
          <Grid item xl={10} md={8} xs={6}>
            Evgeny is an acclaimed pianist, composer, educator, actor and writer<br/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container spacing={0} align="center" justify="center" alignItems="center">
          <Grid item xl={1} md={2} xs={3}>
            <img src={fah_cosial_icon} width="50"/>
          </Grid>
          <Grid item xl={1} md={2} xs={3}>
            <IconButton aria-label="twitter" color="primary" onClick={()=> window.open("https://twitter.com/farahdeenkhan?lang=en", "_blank")}>
              <TwitterIcon/>
            </IconButton>
            <IconButton aria-label="insta" color="primary"  onClick={()=> window.open("https://www.instagram.com/farahdeenkhan/", "_blank")}>
              <InstagramIcon/>
            </IconButton>
          </Grid>
          <Grid item xl={10} md={8} xs={6}>
            Farahdeen is a published author, writer, a painter and poet.<br/>
            
          </Grid>
        </Grid>
      </Grid>
    </Grid>
    </div>
    </>
  )
}




export default function FullArticle() {

  const classes = useStyles();
  const { article_name } = useParams();
  const [bigArticle, setArticle] = useState(null)

  const [background, setBackground, heading, setHeading] = useOutletContext();


  useEffect(() => {
    if (article_name) {
      axios.get(SERVER + '/get_articles').then(res=>{
        let article = res.data.articles.filter((a)=>a._id == article_name)[0];
        setArticle(article);
        setBackground(article.gallery[0]);
        console.log(article.title)
        setHeading(article.title);
        window.scrollTo(0,0)
        })
    }
  }, [article_name])







  return (
    
    <>

      {bigArticle &&

        <div className={classes.articleBig}>
          
          <Helmet>
            <title>Articles - {bigArticle.title}</title>
            <meta name="description" content="Article from the latest posts section" />
          </Helmet>

          <div className={classes.sectionContainer}>
          <div style={{width: '100%', height: '17px', marginBottom: '40px'}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
          <div className={classes.article_inner}>
          
            <Grid container spacing={5}>
              <Grid item xs={12} md={6} lg={8}>
                <div
                  dangerouslySetInnerHTML={{ __html: bigArticle.content }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} >
                {bigArticle.gallery.map((ig)=>{
                  return(
                    <img src={ig} style={{ width: '100%' }} />
                  )
                })}
                
              </Grid>
              
 
              
            </Grid>
            <ArticleFooter/>

          </div>
          </div>
          
        </div>
      }
    </>
  );
}