import {initializeApp} from 'firebase/app';


const firebaseConfig = {
    apiKey: "AIzaSyBwlWrBvitxUD4MWPSeToPEhv_wiipIiOU",
    authDomain: "evgenygenchev.firebaseapp.com",
    projectId: "evgenygenchev",
    databaseURL: "https://evgenygenchev-default-rtdb.europe-west1.firebasedatabase.app",
    storageBucket: "evgenygenchev.appspot.com",
    messagingSenderId: "96359601531",
    appId: "1:96359601531:web:c047efb550fa7be20f19de",
    measurementId: "G-4XSJZ2G941"
  };


  const app = initializeApp(firebaseConfig);
  export default app;