import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Button, Grid, TextField } from '@mui/material';
import SERVER from './URLS';
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles({

  
  section_heading: {
    opacity: 0.8,
    fontSize: '24px',
    fontFamily: 'Abel',
    color: 'white',
    background: '#4B4E53',
    textAlign: 'center',
    margin: '10px',
    padding: '10px',
  },
  articlesContainer: {
    background: "#252b33",
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    backgroundPosition: "center center",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  coolTitile: {
    fontSize: '120px',
    color: "white",
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Darkline'
  },

  tile_background: {
    background: 'black',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1,
    filter: 'alpha(opacity=50)',
    opacity: .5,
  },
  textContent: {
    positon: 'relative',
    bottom: 0,
    width: '60%',
    fontSize: '24px',
    color: "white",
    fontFamily: 'Abel',
    paddingTop: '10px',
    marginTop: '40px'
  },

  galleryTiles: {
    padding: '5%',
    background: 'rgba(119, 47, 26, 0.8)',
    marginTop: '30px'
  },
  galleryHolder: {
    display: 'block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    background: 'rgba(77, 47, 71, 0.95)',
    zIndex: 10001,
  },
  close: {
    textAlign: 'right',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    height: '50px',
    position: 'relative',

    padding: '5px',
  },
  imageTile: {
    width: '100%',
    height: 'auto',
  },
  sectionContainer: {
    marginBottom: '40px'
  },
  video_tile: {
    background: 'black',
    padding: '5px',
    height: "100%",
    cursor: "pointer",
  },
  image_thumb: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    position: 'relative',
    display: 'inline-block'
  },
  fullWidthVideo: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,.9)",
    zIndex: 10000,
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100vw', /* viewport width */
    height: '100vh', /* viewport height */

    justifyContent: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  fullVideoContainer: {
    width: '80%',
    height: '80%',
    padding: '3%',
    margin: 'auto',
  },
  videoHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },
  album_tile: {
    background: "rgba(0,0,0,0.5)",
    padding: '10px',
    margin: '5px',
    width: '100%'
  },
  track_separator: {
    background: 'rgba(38, 64, 139, 0.5)',
    width: '100%',
    textAlign: 'center',
  },
  tracks_container: {
    padding: '20px'
  },
  track: {
    transition: 'all 0.5s ease',
    padding: '10px',
    borderRadius: '10px',
    '&:hover': {
      color: 'yellow',
      background: 'rgba(129, 177, 213, 0.5)',
    }
  },
  albumTitle: {
    padding: '5px',
    background: "rgba(0,0,0,0.6)"
  },
  white_txt: {
    background: '#373E4A',
    color: 'white !important',
    fontFamily: 'Montserrat',
    fontWeight: '500'
  },
  txtLabel: {
    fontFamily: 'Georgia, serif',
    fontWeight: '400',
    fontSize: '18px',
    color: '#7d7b8d'
  },
  letvichka: {
    width: '40%',
    margin: 'auto',
    borderTop: '17px solid #420240'
  }

})

 

export default function ContactForm(props) {
  const classes = useStyles();
  const [windowHeight, setWindowHeight] = useState(0);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');

  const [valid, setValid] = useState(false);
  const [error, setError] = useState('Invalid or blank email!');

  const captcha = useRef(null);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  })

  useEffect(() => {
    if (!validateEmail(email)) {
      setError("Invalid email format")
    }else{
      setError('')
    }
  }, [email])


  function onChangeCaptcha(value) {
    setValid(true);
  }

  const handleSave = () => {
    captcha.current.reset();
    axios.post(SERVER + '/send_contact_mail', {
      name: name,
      email: email,
      subject: subject,
      content: content,
    }).then(res=>{
      setName('');
      setEmail('');
      setSubject('');
      setContent('');
  })}

  return (
    <div>
      <div className={classes.sectionContainer}>
      <div style={{
       backgroundSize: 'cover',
       WebkitBackgroundSize: 'cover',
       backgroundImage: `url('../contact_background.jpg')`,
       backgroundPosition: "center center",
       backgroundRepeat: 'no-repeat',
       position: 'fixed',
       top:0,
       left:0,
       zIndex: -99,
       width: '100%',
       height: `${windowHeight + 100}px`,
       color: 'white',
     }}>
       


     </div>
        
      </div>
      <div className={classes.sectionContainer}>
      <div style={{width: '100%', height: '17px', background: "#252b33"}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
        <div className={classes.articlesContainer}>
        
          <div className={classes.textContent}>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <span className={classes.txtLabel}>
                  Your Name
                </span>
              <TextField
              id="text_inp_name"
              label=""
              maxRows={4}
              value={name}
              onChange={(e)=>{setName(e.target.value)}}
              className={classes.white_txt}
              fullWidth
              variant="filled"
              InputProps={{
                className: classes.white_txt
            }}
            />
              </Grid>
              <Grid item xs={12} md={6}>
              <span className={classes.txtLabel}>
                  Your Email
                </span>
              <TextField
              id="text_inp_email"
              label=""
              maxRows={4}
              value={email}
              onChange={(e)=>{setEmail(e.target.value)}}
              className={classes.white_txt}
              InputProps={{
                className: classes.white_txt
            }}
              fullWidth
              variant="filled"
            />
            {error && (
                  <>
                    
                    <span
                      style={{
                        color: "red",
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Please check your email!
                    </span>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
              <span className={classes.txtLabel}>
                  Subject
                </span>
                <TextField
              id="text_inp_subj"
              label=""
              maxRows={4}
              value={subject}
              onChange={(e)=>{setSubject(e.target.value)}}
              className={classes.white_txt}
              InputProps={{
                className: classes.white_txt
            }}
              fullWidth
              variant="filled"
            /></Grid>
              <Grid item xs={12} md={12}>
              <span className={classes.txtLabel}>
                  Your Message
                </span>
                <TextField
              id="text_inp_context"
              label=""
              multiline
              maxRows={4}
              value={content}
              onChange={(e)=>{setContent(e.target.value)}}
              className={classes.white_txt}
              InputProps={{
                className: classes.white_txt
            }}
              fullWidth
              variant="filled"
              rows={4}
            /></Grid>
            <Grid item xs={12} md={12}>
            <ReCAPTCHA
            ref={captcha}
              sitekey="6Lf1ZYIgAAAAAEcm7_7ntqB5qCFKjDllOFueJZjQ"
              onChange={onChangeCaptcha}
              onExpired={()=>{setValid(false)}}
            />
              {valid && !error.length &&
                <Button onClick={handleSave} variant="contained">Send</Button>
              }
            </Grid>
            </Grid>
              
           
            
            
            

          </div>
        </div>
      </div>
    </div>
  );
}