import React, { useState, useEffect, useRef } from 'react';


import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { MenuItem, Select, FormControl} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from 'react-datepicker';
import SERVER from './URLS';
import { useSearchParams } from "react-router-dom";
import Lightbox from 'react-image-lightbox';
import "./react-datepicker.css";
import Card from '@mui/material/Card';
import { CardMedia } from '@mui/material';
import { jsPDF } from "jspdf";
import { renderToString, renderToStaticMarkup } from 'react-dom/server'
import ticketlogo from '../images/evgeny_genchev_white.png';
import ssbg from '../images/ss_bgd.jpg'
import ssf_logo from '../images/Sweet_Struggle_FESTIVAL_logo.png'
import html2canvas from 'html2canvas';


const images = [
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal1.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal2.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal3.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal7.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal5.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_gal/gal6.jpg',

];


const images_archive = [
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img1.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img2.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img3.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img4.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img5.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img6.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img7.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img8.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img9.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img10.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img11.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img12.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img13.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img14.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img15.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img16.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img17.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img18.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img19.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img20.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img21.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img22.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img23.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/img24.jpg',
];


const images_archive_thumb = [
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img1.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img2.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img3.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img4.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img5.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img6.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img7.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img8.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img9.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img10.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img11.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img12.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img13.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img14.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img15.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img16.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img17.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img18.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img19.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img20.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img21.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img22.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img23.jpg',
  'https://storage.googleapis.com/evgenygenchev_static/ssf_archive/thumbs/img24.jpg',
];

const useStyles = makeStyles({

  show: {
    height: '150px',
    background: 'transparent',
    //background: 'green'
  },
  hide: {
    height: '50px',
    background: '#1d242d',
    paddingLeft: '5px',

  },
  section_heading_small: {
    fontSize: '60px',
    fontFamily: 'Darkline',
    color: 'white',
    //background: '#4B4E53',
    textAlign: 'center',
    //margin: '10px',
    //padding: '10px',
   },
  articlesContainer: {
    background: "rgb(29, 36, 45, 1)",
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    backgroundPosition: "center center",
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '30px'
  },
  type_descr: {
    fontSize: '18px',
    color: '#9f9f9f',
    display: "block",
    paddingLeft: '40px'
  },
  coolTitile: {
    fontSize: '40px',
    color: "white",
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Abel',
    marginTop: '30px',
    paddingTop: '30px'
  },
  coolTitile2: {
    fontSize: '35px',
    color: "white",
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Abel'
  },
  tile_background: {
    background: 'black',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1,
    filter: 'alpha(opacity=50)',
    opacity: .5,
  },
  textContent: {
    positon: 'relative',
    bottom: 0,
    width: '80%',
    fontSize: '24px',
    color: "white !important",
    fontFamily: 'Abel',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    marginLeft: '10%',
    marginTop: '30px',
    marginBottom: '20px'
  },

  textContent_left: {
    positon: 'relative',
    bottom: 0,
    width: '80%',
    fontSize: '22px',
    color: "white !important",
    fontFamily: 'Abel',
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'left',
    marginLeft: '10%',
    marginTop: '30px',
    marginBottom: '20px'
  },

  video: {
    position: "fixed",
    left: '50%', /* % of surrounding element */
    top: '50%',
    transform: 'translate(-50%, -50%)', /* % of current element */
    zIndex: -1,
  },
  sectionContainer: {
    marginBottom: '40px'
  },

  image_thumb: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    position: 'relative',
    display: 'inline-block'
  },
  fullWidthVideo: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,.9)",
    zIndex: 10000,
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100vw', /* viewport width */
    height: '100vh', /* viewport height */

    justifyContent: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  fullVideoContainer: {
    width: '80%',
    height: '80%',
    padding: '3%',
    margin: 'auto',
  },
  videoHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },
  subheading: {
    fontSize: '26px',
    fontFamily: "Darkline",
    //position: 'absolute',
    //transform: 'translate(-50%, -200%)',
    fontWeight: 'bolder'
  },
  formControl: {
    "& .MuiInputBase-root": {
      color: "#6EC177 !important",
      borderColor: "#6EC177",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "100px",
      minWidth: "120px",
      justifyContent: "center"
    },
    "& .MuiSelect-select.MuiSelect-select": {
      paddingRight: "0px"
    },
    "& .MuiSvgIcon-root": {
      color: 'white !important',
      fontSize: '30px'
    }
  },
  select: {
    width: "auto",
    fontSize: "18px",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  selectIcon: {
    position: "relative",
    color: "#6EC177",
    fontSize: "14px"
  },
  paper: {
    borderRadius: 12,
    marginTop: 8
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    "& li": {
      fontWeight: 500,
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: "18px"
    },
    "& li.Mui-selected": {
      color: "white",
      background: "#6EC177"
    },
    "& li.Mui-selected:hover": {
      background: "#6EC177"
    },
    "& svg.MuiSvgIcon-root": {
      color: "white"
    }
  },
  letvichka: {
    width: '40%',
    margin: 'auto',
    borderTop: '17px solid #420240'
  },
  payments_tab: {
    background: 'rgba()'
  },
  txtInput: {
    background: 'transparent',
    border: '1px solid #6EC177',
    color: 'white',
    fontSize: '22px',
    margin: '5px',
    padding: '5px'
  },
  payBtn: {
    width: '100px',
    textAlign: 'center',
    background: '#6EC177',
    border: '1px solid white',
    color: 'white',
    fontSize: '22px',
    cursor: 'pointer',marginTop: '30px'
  },
  imageTile: {
    width: '100%',
    height: 'auto',
    cursor: 'pointer'
},
selectables: {
  color: 'white',
  marginTop: '10px',
  marginBottom: '10px',
  fontSize: '20px'
},
step_span: {
  display: 'inline-block',
  padding: '10px',
  fontSize: "24px",
  fontWeight: '300',
  color: "#e600cf"
},
inline_li: {
  display: 'inline-block',
  padding: '10px',
  "@media (min-width: 600px)": {
    transform: "translate(-20px, 0px);"
  }
  
},
download_btn: {
  margin: '40px',
  color: 'white',
  background: '#e600cf',
  border: 'none',
  padding: '10px',
  fontSize: '24px',
  cursor: 'pointer'
},
mandSpan: {
  color: 'red',
  fontSize: "24px",
  display: 'inline-block',
  padding: '3px',
  fontFamily: 'Abel',
  transform: 'translate(-20, 0)'

},
expand: {
  WebkitTransition: 'all 1s ease-out',
  transition: 'all 1s ease-out',
  transform: 'scale(1.5)'
 },
 shrink: {
  WebkitTransition: 'all 1s ease-out',
  transition: 'all 1s ease-out',
  transform: 'scale(1)'
 },

})

            
            
const products = {
  0: {
    value: 30,
    name: 'Day-Pass | €30',
    stripe_id: 'price_1LBZjrEo1kl329Yob6Y9cyyl',
    stripe_id_discount: 'price_1LBZjrEo1kl329Yob6Y9cyyl',
  },
  1: {
    value: 150,
    name: 'Active Listener | €150',
    stripe_id: 'price_1LBZhLEo1kl329YozYWQQ7xT',
    stripe_id_discount: 'price_1LQF0SEo1kl329YomtWx3Ehl',
  },
  2: {
    value: 400,
    name: 'Full-Time Participant | €400',
    stripe_id: 'price_1LBZd2Eo1kl329YoLqLMPQ6G',
    stripe_id_discount: 'price_1LQF2cEo1kl329YoHuel3FUR',
    tier2_discount: 'price_1LQF2sEo1kl329YoFNqgoXq0',
    tier3_discount: 'price_1LQF37Eo1kl329YoEyoLbaDv'

  }
  
}


/**
 * 
 * ,
  
 */



function Ticket({qrcode, scaleup}) {

  return(

 
    <div style={{
      width: '210px', 
      height: '480px',
      display: 'inline-block',
      fontSize:"11px !important", 
      textAlign: 'center',
      marginTop: '100px'
    }} id='your_ticket'>
      <img src='../evgeny_genchev_white.png' width='160' style={{zIndex: 1, position: 'relative', top: 10, left: 15}}/><br/>
      <img src='../ss_bgd_small.jpg' width='220' height='500' style={{zIndex: 0, position: 'relative', top: -100, left: 0}}/><br/>
      <div style={{zIndex: 0, position: 'relative', top: -480, left: 0}}>
      <span style={{display: 'block', marginBottom: '12px', marginTop: '12px', color: "white !important", textAlign: "center", wordSpacing: 'normal !important'}}>Here is your ticket:</span>
      
      <img src={`data:image/jpeg;base64,${qrcode}`} width="70" height='70'/>
      <span style={{display: 'block', marginBottom: '12px'}}>
        <img src={ssf_logo} width='250' height='170' style={{zIndex: 0, position: 'relative', top: 20, left: -17}}/>
        </span>
        <span style={{display: 'block', marginTop: '20px', top: -50}}>
        ...
        </span>
      </div>
    </div>
 
  )
}

export default function FestivalInfoAndPayment(props) {
  const classes = useStyles();
  const [product, setProduct] = useState('0');
  const [email, setEmail] = useState(null);
  const [error, setError] = useState('Invalid or blank email!');
  const [day, setDay] = useState(new Date('08/07/2023'));
  const [daystr, setDayStr] = useState('fulltime');
  const [startIndex, setStartIndex] = useState(0);
  const [startIndexArchive, setStartIndexArchive] = useState(0);
  const [galleryOpen, setGalleryOpen] = useState(false);
  const [galleryArchiveOpen, setGalleryArchiveOpen] = useState(false);
  const [code, setCode] = useState('');
  const [validVoucher, setValidVoucher] = useState(false);
  const [qrcode, setQRcode] = useState('')
  const [name, setName] = useState("")
  const ticket_ref = useRef(null)
  const [discount_level, setDiscountLevel] = useState(1);

  const [searchParams] = useSearchParams();

  const [session, setSession] = useState(null);

  const payment_section_ref = useRef(null);

  const [scaleup, setScaleUp] = useState(false);

  const [action, setAction] = useState(true);


  useEffect(()=>{
   setTimeout(()=>{
    setAction(!action)
   },1000)
  },[action])


  const generateTicket = () => {

    let element = <Ticket qrcode={qrcode}/>;
 
    console.log(document.getElementById('your_ticket'));
    document.getElementById('your_ticket').style.transform = 'scale(3)'

    html2canvas(document.getElementById('your_ticket')).then(function(canvas) {     
        //alert('here');   
        var imgData = canvas.toDataURL('image/png');      
        console.log(imgData)        
        var doc = new jsPDF('p', 'mm', 'a4');
        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        doc.addImage(imgData, 'PNG', 20, 5, width-40, height, undefined, 'SLOW');
        doc.save('ticket.pdf');
        var blob = doc.output('blob');
        //blob.save()
        console.log(blob)
        var data = new FormData();
        data.append('pdf', new File([blob], 'ticket.pdf'));
        data.append('email', session.customer_details.email);
        data.append('payment_session', searchParams.get('session_id'));
        data.append('day', session.metadata.day);
        data.append('amount', session.amount_total/100);
        data.append('name', session.metadata.name);
        data.append('code', session.metadata.code);
        console.log(data)
        axios.post(SERVER + '/send_ticket', data, {headers: {'enctype':'multipart/form-data',"Content-Type": "multipart/form-data"}})
      }
    )
    document.getElementById('your_ticket').style.transform = 'scale(1)'
    
  }

  useEffect(()=>{
    console.log(searchParams.get('session_id'))
    let session_params = searchParams.get('session_id');
    if(session_params){
      axios.post(SERVER + '/get-stripe-session', {stripe_session: session_params}).then(res=>{ 
        console.log()
        setSession(res.data.session_data);
        setQRcode(res.data.qrcode)
        axios.post(SERVER + '/invalidate-voucher', {code: res.data.session_data.metadata.code}).then(res=>{
          console.log(res)
        })
        console.log(res.data)
      })
    }
  },[searchParams])


  

  const handleTicketChange = (index) => {
    console.log(index)
    setProduct(index)
  }

  const handleChange = (newValue) => {
    console.log(typeof newValue);
    let d = newValue.getDate();
    let m = newValue.getMonth()+1;
    let y = newValue.getFullYear();

    setDay(newValue);
    setDayStr(d+"/"+m+"/"+y);
  };

  useEffect(()=>{
    console.log(day);
  },[day])
  useEffect(()=>{
    console.log(daystr);
  },[daystr])
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    if (!validateEmail(email)) {
      setError("Invalid email format")
    }else{
      setError(null)
    }
  }, [email])


  const checkVoucher = () => {
    let code_data = {
      code: code,
    }
    if(code && code.length == 13 ){
      axios.post(SERVER + "/check_discount", code_data).then((res) => {
        console.log(res.data.status == "OK");
        if (res.data.status == "OK") {
           if(['SSF_15_rdXD5C', 'SSF_15_XQNvIY', 'SSF_15_v271Vf', 'SSF_15_5kgkL7'].includes(code)){
            setDiscountLevel(2);
           }
           if(['SSF_15_1BZQIj', 'SSF_15_q9MHGD', 'SSF_15_O2kJNv'].includes(code)){
            setDiscountLevel(3);
           }
          setValidVoucher(true);
        } else {
          setValidVoucher(false);
        }
      })
    }else{
      setValidVoucher(false);
    }
    
  }

  useEffect(()=>{
    checkVoucher()
  },[code])

  const handleStripeCheckoutButton = () => {
    //check for dicount
        let stripe_id = products[product].stripe_id;
        if (validVoucher){
          stripe_id = products[product].stripe_id_discount;
          if(discount_level == 2){
            
            stripe_id = products[product].tier2_discount;
          }
          if(discount_level == 3){
           
            stripe_id = products[product].tier3_discount;
          }
        }

        let data = {
          email: email,
          day: daystr,
          stripe_id: stripe_id,
          code: code,
          name: name,
          product: product,
        };

        console.log(data);
        //return 0;

        axios.post(SERVER + "/create-stripe-checkout", data).then((res) => {
          window.location.href = res.data;
        });
     
    
    
  }

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    getContentAnchorEl: null
  };

  return (
    <div>
      <Helmet>
        <title>Evgeny Genchev - Sweet Struggle Festival</title>
        <meta name="description" content="" />
      </Helmet>
      {session && (
            
        
      <div className={classes.sectionContainer} style={{transform: "translate(0, -30%)"}}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}></div>
          </div>
          <div className={classes.textContent}>
            
          <div className={classes.section_heading_small}>Your Ticket</div>
         
            <div className={classes.textContent} style={{marginTop: '0px'}}>
              Your payment has been processed succesfully.<br/>
              Email: {session.customer_details.email}<br/>
              Day: {session.metadata.day}<br/>
              Amount Paid: {session.amount_total/100} {session.currency.toUpperCase()}<br/>
              Name: {session.metadata.name}<br/>
              
              <button onClick={generateTicket} className={`${classes.download_btn} ${action?classes.expand:classes.shrink}`}>Download</button><br/>
              <img src={`data:image/jpeg;base64,${qrcode}`} width="300" height='300'/><br/>
              
              <Ticket qrcode={qrcode} scaleup={true}/>
            </div>
          </div>
        </div>
      </div>
      )}
      
      
      <div className={classes.sectionContainer}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.textContent}>
         
            <Grid container>
              <Grid item xl={6} sm={12}>
                <Grid container>
                  <Grid item sm={12} className={classes.coolTitile}>
                    When?
                  </Grid>
                  <Grid item sm={12} className={classes.coolTitile2}>
                    7 - 13 August, 2023
                  </Grid>
                  <Grid item sm={12} className={classes.coolTitile}>
                    Where?
                  </Grid>
                  <Grid item sm={12} className={classes.coolTitile2}>
                    Plovdiv, Bulgaria
                  </Grid>
                  <Grid item sm={12} className={classes.coolTitile}>
                    What?
                  </Grid>
                  <Grid item sm={12} className={classes.coolTitile2}>
                    {false && <Typography>Opening Concert by Evgeny Genchev</Typography>}
                    <Typography>Masterclasses &amp; 1:2:1 classes</Typography>
                    <Typography>Participants Concert</Typography>
                    <Typography>Group Trips</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xl={6} sm={12}>
              <Grid container spacing={8}>
              {images.map((i, ind) => {
                                    return (
                                        <Grid item xs={12} sm={6} md={4}>
                                            <Card sx={{ maxWidth: 385 }} onClick={() => { setStartIndex(ind); setGalleryOpen(true) }} className={classes.imageTile}>
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={i}
                                                    alt="Click to enlarge"
                                                />
                                            </Card>
                                        </Grid>
                                    )

                                })}
                {galleryOpen && (
                  <Lightbox
                    mainSrc={images[startIndex]}
                    nextSrc={images[(startIndex + 1) % images.length]}
                    prevSrc={
                      images[(startIndex + images.length - 1) % images.length]
                    }
                    onCloseRequest={() => setGalleryOpen(false)}
                    onMovePrevRequest={() =>
                      setStartIndex(
                        (startIndex + images.length - 1) % images.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setStartIndex((startIndex + 1) % images.length)
                    }
                  />
                )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.section_heading_small}>About</div>
          
          <div className={classes.textContent_left}>
            <p>
              The Sweet Struggle Festival is the brainchild of the prominent pianist Evgeny Genchev. 
              This event is conceived with the idea to continue what Evgeny initially started on his YouTube channel 
              and went viral during the start of the pandemic - to build a community of like-minded people who do 
              what they love and push through difficulties to become better versions of themselves. The centre of 
              focus during the festival will be music – the universal language that links us all. 
            </p>
            <p>
              Evgeny’s mission is to share his knowledge and experience, both as a performer and as a teacher, to inspire,
              assist, and support the participants on their musical journey. At The Sweet Struggle Festival we welcome piano players, pedagogues, 
              experts, and enthusiasts from around the globe to join us in this celebration of music, and consequently depart from it with a deeper
               appreciation and understanding of its works.
            </p>
            <p>
              The Sweet Struggle Festival will take place between 7<sup>th</sup> and 13<sup>th</sup> of August, in Runiton Piano Center, in Plovdiv, Bulgaria, the picturesque hometown of Evgeny Genchev.
            </p>
            <p>
              The program will include an opening ceremony by the festival’s founder Evgeny Genchev, after which 
              there will be five days of masterclasses and workshops with the participants, concluding with a participant’s recital, 
              which will include many surprise performances.
            </p>
          </div>
          <div className={classes.textContent}>
            
          </div>
        </div>
      </div>
     
      <div className={classes.sectionContainer}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.section_heading_small}>How To Apply</div>
          
          <div className={classes.textContent_left}>
            <h3>APPLICATION PROCESS</h3>
            <p>
               
               
                  <Grid container>
                    <Grid item xl={1}>
                    <span className={classes.step_span}>Step 1</span>
                    </Grid>
                    <Grid item xl={11}>
                    <span className={classes.inline_li}>Prepare a short statement (less than 300 words, in English or Bulgarian) describing your musical background 
                    and why you want to participate in the Sweet Struggle Festival</span>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={1}>
                    <span className={classes.step_span}>Step 2</span>
                    </Grid>
                    <Grid item xl={11}>
                    <span className={classes.inline_li}>Provide a list with the repertoire selection you would like to play during the festival</span>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={1}>
                    <span className={classes.step_span}>Step 3</span>
                    </Grid>
                    <Grid item xl={11}>
                    <span className={classes.inline_li}>Payment of tuition fee. Please note that after the payment has been processed, you will be redirected back to this page. 
                    Make sure to click the "Download" button, in order to save your ticket and to receive a confirmation email. Make sure to include its PDF attachment as part of your application</span>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xl={1}>
                    <span className={classes.step_span}>Step 4</span>
                    </Grid>
                    <Grid item xl={11}>
                    <span className={classes.inline_li}>Send all materials to festival@evgenygenchev.net by August 5<sup>th</sup></span>
                    </Grid>
                  </Grid>
                  
 
            </p>
            <h3>NOTE:</h3>
            <p>
            Step 1 and Step 2 are optional for Active Listeners.  
            </p>
            <p>
            Day-Pass holders can go directly to the <a href="#payment_section" style={{textDecoration: 'none', color: "#e600cf"}}>payment section</a> of the page, make their preferred selection, and after completion of the process, 
            save the confirmation QR code they have received. This will be their entry pass, which should be shown upon arrival at the festival desk. 
            </p>
            <h3>FESTIVAL TUITION</h3>
            
            <p>
              <li>Full-Time Participant | €400<br/>
              <span className={classes.type_descr}>Full-Time Participants will receive three (3) lessons with Evgeny and have access to all the events and activities during the festival.</span>
              </li>
              <li>Active Listener | €150<br/>
              <span className={classes.type_descr}>Active Listeners will have access to all events and activities during the festival.</span>
              </li>
              <li>Day-Pass | €30<br/>
              <span className={classes.type_descr}>Day-Pass entitles you access to all activities and events in a chosen day during the festival.</span>
              </li>
            </p>
            <h3>SCHOLARSHIPS</h3>
            <p>
            Partial scholarships that cover tuition are available to participants in need of financial assistance. 
            Please state on the application form whether you would like to be considered for a scholarship
            </p>
          </div>
 
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.section_heading_small}>General Information</div>
          
          <div className={classes.textContent_left}>
            <h3>LOCATION</h3>
            <p>
              The festival will take place at Runiton Piano Center.<br/>
              <br/>
              Address: bul. "Dunav" 1, 4003, Plovdiv, Bulgaria<br/>
              <br/>
              <center>
              <iframe
              
              style={{border:0, width: '100%', maxWidth: '600px', height: '300px'}}
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBTr1cJDTrH7xlHnjNxvixWi9vtGG8jOww&q=runiton+piano+center+plovdiv+dunav+1">
            </iframe>
            </center>
              
            </p>
            <h3>PRACTICE PIANOS</h3>
            <p>
              Festival participants may practice for up to three hours per day in the Piano Center. Sign-up sheets will be available.
            </p>
            <h3>ARRIVAL &amp; REGISTRATION</h3>
            <p>
            All full-time participants and active listeners must arrive by August 7<sup>th</sup> and register in order to receive their participant’s 
            badge and take part in the festival activities.  The festival will conclude on August 13<sup>th</sup>.
            </p>
            <h3>TRAVEL</h3>
            <p>
            The recommended airport is Plovdiv Airport, which is only about a 20-minute taxi ride from campus. However, if flying directly to Plovdiv 
            is not an option, one can fly to Sofia Airport. 
            </p>
            <p>
            Please contact us if you need recommendations regarding airport transfer to Plovdiv. 
            </p>
            <h3>HOTEL INFORMATION</h3>
            <p>
            For visitors and participants who prefer to stay at any nearby hotel, there are both scenic and suitable options well within walking distance from the campus.
            </p>
            <p>
            For any further enquiries contact: festival@evgenygenchev.net
            </p>
          </div>
 
        </div>
      </div>
      <div className={classes.sectionContainer}>
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.section_heading_small}>Gallery</div>
          
          <div className={classes.textContent_left}>
          <Grid container spacing={2}>
              {images_archive_thumb.map((i, ind) => {
                                    return (
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Card sx={{ maxWidth: 385 }} onClick={() => { setStartIndexArchive(ind); setGalleryArchiveOpen(true) }} className={classes.imageTile}>
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={i}
                                                    alt="Click to enlarge"
                                                />
                                            </Card>
                                        </Grid>
                                    )

                                })}
                {galleryArchiveOpen && (
                  <Lightbox
                    mainSrc={images_archive[startIndexArchive]}
                    nextSrc={images_archive[(startIndexArchive + 1) % images_archive.length]}
                    prevSrc={
                      images_archive[(startIndexArchive + images_archive.length - 1) % images_archive.length]
                    }
                    onCloseRequest={() => setGalleryArchiveOpen(false)}
                    onMovePrevRequest={() =>
                      setStartIndexArchive(
                        (startIndexArchive + images.length - 1) % images_archive.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setStartIndexArchive((startIndexArchive + 1) % images_archive.length)
                    }
                  />
                )}
                </Grid>
          </div>
          <div className={classes.textContent}>
            
          </div>
        </div>
      </div>
      {false && 
      <div className={classes.sectionContainer} ref={payment_section_ref} id="payment_section">
        <div className={classes.articlesContainer}>
          <div style={{ width: "100%", height: "5px" }}>
            <div className={classes.letvichka}>.</div>
          </div>
          <div className={classes.section_heading_small}>Tickets</div>
          
          <div className={classes.textContent}>
          Please select a ticket type and fill in all <span className={classes.mandSpan}>*</span>necessary details. <br/>
          Make sure to choose a date, in case you have selected Day-Pass ticket.
            
          </div>
          <div className={classes.textContent_solid}>
            <Grid container alignContent="center" alignItems="center">
              <Grid item xs={12} xl={12} style={{ textAlign: "center" }}>
                <FormControl className={classes.formControl}>
                  <Select
                    labelId="ticket-type-select"
                    id="demo-simple-select"
                    value={product}
                    classes={{
                      select: classes.select,
                      icon: classes.selectIcon,
                    }}
                    MenuProps={menuProps}
                    onChange={(e) => {
                      handleTicketChange(e.target.value);
                    }}
                  >
                    {Object.keys(products).map((k) => {
                      return <MenuItem value={k}>{products[k].name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12} style={{ textAlign: "center" }}>
                <div className={classes.selectables}>
                <form>
                  <label>
                  Email:<span className={classes.mandSpan}>*</span>
                    <br />
                    <input
                      className={classes.txtInput}
                      type="text"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </label>
                </form>
                {error && (
                  <>
                    
                    <span
                      style={{
                        color: "red",
                        fontWeight: 500,
                        fontSize: "20px",
                      }}
                    >
                      Please check your email!
                    </span>
                  </>
                )}
                <form>
                  <label>
                  Name:<span className={classes.mandSpan}>*</span>
                    <br />
                    <input
                      className={classes.txtInput}
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </label>
                </form>
                </div>
              </Grid>

              {['0','3'].includes(product) && 
                <Grid
                  item
                  xl={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                  id="root-portal"
                >
                  <div className={classes.selectables}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    Date:<span className={classes.mandSpan}>*</span>
                    <br />
                    <DatePicker
                      portalId="root-portal"
                      label="Choose Date"
                      selected={day}
                      onChange={handleChange}
                      minDate={new Date("08/07/2023")}
                      maxDate={new Date("08/13/2023")}
                      dateFormat='dd/MM/yyyy'
                      popperPlacement="auto"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px",
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
                          boundariesElement: "viewport",
                        },
                      }}
                      className={classes.datepicker}
                    />
                  </LocalizationProvider>
                  </div>
                </Grid>
              }
              
              {['1','2','3'].includes(product) && 
                <Grid
                  item
                  xl={12}
                  xs={12}
                  style={{ textAlign: "center" }}
                  id="root-portal"
                >
                  <div className={classes.selectables}>
                  <form>
                  <label>
                    Discount Code:
                    <br />
                    <input
                      className={classes.txtInput}
                      type="text"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);

                      }}
                    />
                  </label>
                </form>
                <br/>
                {validVoucher && 
                  <span>Discount Applied! :)</span>
                }
                {!validVoucher && 
                  <span>Invalid Voucher!</span>
                }
                </div>
                </Grid>
            }
            </Grid>

      

            <Grid
              container
              justifyContent="center"
              align="center"
              justify="top"
              alignItems="top"
              className
            >
              <Grid item xs={12}>
              <div className={classes.selectables}>
                <Typography>
                  You will be redirected to external link in order to complete
                  the payment.<br/>
                  Once the payment is completed, you will be redirected back to this page with info for your payment and a button to download your ticket.<br/>
                  Please note, completed payments are non-refundable.

                </Typography>
                <button
                  onClick={handleStripeCheckoutButton}
                  className={classes.payBtn}
                >
                  PAY
                </button>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      }
    </div>
  );
}