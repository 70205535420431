import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { getStorage, listAll, getDownloadURL, uploadBytes, uploadString, ref, getMetadata } from "firebase/storage";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button, Grid } from '@mui/material';
import { fontSize } from '@mui/system';
import app from '../firebase.js'
import { getDatabase,  set } from "firebase/database";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SERVER from './URLS';

const storage = getStorage();
const database = getDatabase(app);

const basePathArticles = 'articles'


const useStyles = makeStyles({

 form: {
     background: 'rgba(255,255,255,1)',
     color: 'black',
     width: "80%",
     padding: '20px',
     margin: 'auto'
  },
  textarea: {
   width: '100%'
  },
  heading: {
   margin: '20px',
   background: "rgba(0,0,0,0.5)",
   color: "white",
   fontSize: '22px',
   textAlign: "center"
  }
})


function TourDate({td, onChange, ind}){
 const [_id, setID] = useState(null);
 const [date, setDate] = useState("");
 const [location, setLocation] = useState("");
 const [venue, setVenue] = useState("");
 const [url, setUrl] = useState("");

 useEffect(()=>{
  if(td){
   setID(td._id);
   setDate(td.date);
   setLocation(td.location);
   setVenue(td.venue);
   setUrl(td.url);
  }
 },[])

 useEffect(()=>{

  onChange(ind, {
   _id: _id,
   date: date,
   location: location,
   venue: venue,
   url: url
  })
 },[date, location, venue, url])

 return(
  <>
   <TextField
    id="date"
    label="Tour Date"
    value={date}
    onChange={e => setDate(e.target.value)}
    fullWidth
   />
   <TextField
    id="location"
    label="Tour Location"
    value={location}
    onChange={e => setLocation(e.target.value)}
    fullWidth
   />
   <TextField
    id="venue"
    label="Tour Venue"
    value={venue}
    onChange={e => setVenue(e.target.value)}
    fullWidth
   />
   <TextField
    id="url"
    label="Tickets URL"
    value={url}
    onChange={e => setUrl(e.target.value)}
    fullWidth
   />
  </>

 )
}

function TourDatesForm() {
  const [tour_dates, setTourDates] = useState([])
  const classes = useStyles();

  useEffect(()=>{
   axios.get(SERVER + '/get_tour_dates').then(res=>{
            
            setTourDates(res.data.dates);
        })
  },[])
 
  function handleSubmit(event) {
   axios.post(SERVER + '/save_tour_dates', tour_dates).then(res=>{
    axios.get(SERVER + '/get_tour_dates').then(res=>{
            
            setTourDates(res.data.dates);
        })
   })
   
  }

  
  const addDate = () => {
   let ndates = [...tour_dates];
   ndates.push({'date': '', location: '', 'url': '', venue: ''});
   setTourDates(ndates)
  }

  const updateDate = (ind, tourDate) => {


    let ndates = [...tour_dates];
    ndates[ind] = {...tourDate};
    setTourDates(ndates);
  }
  

  return (
    <div className={classes.form}>
   

     <div className={classes.heading}>Tour Dates Admin</div>

     <Grid container spacing={5} align="center" justify="center" alignItems="center">
     {tour_dates?.map((td, ind)=>{
      return(
       <Grid item xs={12} md={6}>
        <TourDate td={td} onChange={updateDate} ind={ind}/>
       </Grid>
      )
     })}
     </Grid>
     
     <Button onClick={addDate} variant="outlined" >Add More</Button>
     
     <Button onClick={handleSubmit} variant="outlined" >Submit</Button>
    
    </div>
  );
}

export default TourDatesForm;