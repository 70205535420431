

import TopNav from './TopNav'
import Footer from './Footer'

import React, { useState, useEffect } from 'react';

import '../fonts/Darkline/Darkline.ttf';

import {
  Outlet,
  useNavigate,
  useLocation

} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { Helmet } from "react-helmet";
import { useCookies } from 'react-cookie';
import pattern from '../images/pattern.png'
import SubscribeForm from './SubscribeForm';
import AnimationCanvas from './cnv';
import Scroll from './ScrollToTop';

import ssf_logo_small from '../images/Sweet_Struggle_FESTIVAL_logo_small.png'
import plovdiv_banner from '../images/zobf_960_360.jpg'

const useStyles = makeStyles({
  main_content: {
    background: '#1d242d',
  },
  hcenter: {
    //WebkitTransition: 'all .2s ease-out',
    //transition: 'all .01s ease-out',
    left: '50%',
    top: '50%',
    position: 'fixed',
    fontSize: '160px',
    color: "white",
    textAlign: 'center',
    fontFamily: 'Darkline',
    transform: 'translate(-50%, -40%)',
    width: '100%',
    background: 'rgba(25,25,25,0)',
    lineHeight: '120px'
  },
  subheading: {
    fontSize: '26px',
    fontFamily: "Montserrat",
    //position: 'absolute',
    //transform: 'translate(-50%, -200%)',
    fontWeight: 'bolder'
  },
  subheading_darkline: {
    fontSize: '120px',
    fontFamily: "Darkline",
    //position: 'absolute',
    //transform: 'translate(-50%, -200%)',
    fontWeight: 'bolder',
    color: '#000'
  },
  preloader: {
    display: 'none'
  },
  shutterHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%'
  },
  leftShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',

  },
  rightShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
  },
  dialog: {
    background: 'rgba(0,0,0,0.3)'
  },
  dialog_title: {
    background: "rgba(15,15,15,0.6)",
    color: 'white'
  },
  title_inner: {
    display: 'inline-block',
    padding: "0px 30px"
  },
  dialog_content: {
    background: "rgba(55,55,55,0.6)"
  },
  dialog_actions: {
    background: "rgba(55,55,55,0.6)"
  },
  cnv_container: {
    width: '100%',
    height: '100%'
  },
  pixelOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -98,
    width: '100vw',
    height: "100vh",
    color: 'white',
    background: `url(${pattern})` /* or any other overlay image/color */
  }
})



const img_mapper = {
  '/music': { url: '../music.jpg', heading: 'Music' },
  '/contact': { url: '../contact_background.jpg', heading: 'Contact' },
  '/articles': { url: '../videos_image.jpg', heading: 'Latest' },
  '/allArticles': { url: '../videos_image.jpg', heading: 'All Articles' },
  '/tour': { url: '../videos_image.jpg', heading: 'Tour' },
  '/admin': { url: '../videos_image.jpg', heading: 'Admin' },
  '/about': { url: '../videos_image.jpg', heading: 'About' },
  '/meet-the-band': { url: '../videos_image.jpg', heading: 'About' },
  '/meet-the-band/': { url: '../videos_image.jpg', heading: 'About' },
  '/': { url: '../egen_home.jpg', heading: '' },
  '/home': { url: '../egen_home.jpg', heading: '' },
  '/videos': { url: '../piano_hands.jpg', heading: 'Videos', subheading: 'Music Videos | Tutorials | Vlogs' },
  '/privacy': { url: '../piano_hands.jpg', heading: 'Privacy Policy', subheading: 'We respect your privacy' },
  '/ssf': { url: '../ss_bgd.jpg', heading: <img src={ssf_logo_small} style={{width: '80%', maxWidth: '900px'}}/> }

}

export default function Home() {
  let navigate = useNavigate();

  const [background, setBackground] = useState('');
  const [heading, setHeading] = useState('');
  const [subheading, setSubHeading] = useState('');
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useCookies(['subscribed_evgenygenchev','suppressed_evgenygenchev']);


  useEffect(()=>{
    console.log(heading)
  },[heading])
  
  useEffect(() => {
    if (cookies['subscribed_evgenygenchev'] == 'true' || cookies['suppressed_evgenygenchev'] == 'true') {
      setOpen(false);

    } else {
      setTimeout(() => {
        setOpen(true)
      }, 10000)
    }
  }, [cookies, location])




  useEffect(() => {
    if (!location.pathname.includes("articles")) {
      if (location.pathname == '/') {
        navigate('/home')
      }
      try {
        if (location.pathname.includes("/unsubscribe/")){
          setBackground(img_mapper['/home'].url);
          setHeading('Unsubscribe');
        } else
        {
          setBackground(img_mapper[location.pathname].url);
          setHeading(img_mapper[location.pathname].heading);
          setSubHeading(img_mapper[location.pathname].subheading ? img_mapper[location.pathname].subheading : "");
          window.scrollTo(0, 0);
  
        }
  
  
      } catch {
  
        setBackground(img_mapper['/home'].url);
        setHeading('Page Not Found');
      }

    }


    
  }, [location])

  useEffect(() => {
    if (!location.pathname.includes("articles")) {
      if (location.pathname == '/') {
        navigate('/home')
      }
      try {
        if (location.pathname.includes("/unsubscribe/")){
          setBackground(img_mapper['/home'].url);
          setHeading('Unsubscribe');
        } else
        {
          setBackground(img_mapper[location.pathname].url);
          setHeading(img_mapper[location.pathname].heading);
          setSubHeading(img_mapper[location.pathname].subheading ? img_mapper[location.pathname].subheading : "");
          window.scrollTo(0, 0);
  
        }
  
  
      } catch {
  
        setBackground(img_mapper['/home'].url);
        setHeading('Page Not Found');
      }

    }


    
  }, [])


  const [windowHeight, setWindowHeight] = useState(0);
  const classes = useStyles();
 

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  })

  const handleOpen = () => {
    setOpen(true)
  }




  return (
    <>
      
      <TopNav onSubscribe={handleOpen}/>
      <div>
        <Helmet>
          <title>Evgeny Genchev - Personal Site</title>
          <meta name="description" content="Welcome to the personal page of the pianist, composer, actor and writer Evgeny Genchev" />
          
        </Helmet>
        <div style={{
          backgroundSize: 'cover',
          WebkitBackgroundSize: 'cover',
          backgroundImage: `url(${background})`,
          backgroundPosition: "center center",
          backgroundRepeat: 'no-repeat',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: -99,
          width: '100vw',
          height: "100vh",
          color: 'white',
        }}>
          <div style={{ width: '100%', height: '100%' }} className={classes.cnv_container}>
          {['/', '/home'].includes(location.pathname) &&
          <>
              <AnimationCanvas />
              
                        </>
            }

          </div>
        </div>
        {location.pathname.search('/articles')!=-1 &&
              <div className={classes.pixelOverlay}></div>
            }
        <div>
          
          <div style={{
            position: 'relative',
            height: `${windowHeight}px`,
            width: '100%',
            color: "white",
            zIndex: '-1'
          }}>
            <div className={classes.hcenter}>

            {heading}<br />
            <span className={!['/ssf'].includes(location.pathname)?classes.subheading:classes.subheading_darkline}>{subheading}</span>
          </div>
          </div>
        </div>
        {['/', '/home'].includes(location.pathname) &&
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: `${windowHeight/2}px`,
            width: '100%',
            color: "white",
            zIndex: '0',
            textAlign: 'center',
            paddingTop: `${Math.ceil(windowHeight/8)}px`,
            opacity: '1',
            marginTop: '150px',
            background: 'transparent'
          }}>
              
            </div>
            }
        
         
          <SubscribeForm open={open} setOpen={setOpen}/>
          
         
        <Scroll showBelow={250} />
        <Outlet context={[background, setBackground, heading, setHeading]} />
        <Footer onSubscribe={handleOpen}/>
      </div>
    </>
  );
}


