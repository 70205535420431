import React, { useState, useEffect, useRef, useMemo, Suspense, useCallback } from 'react';
import * as THREE from "three";
import { Canvas, extend, useFrame, useLoader, useThree } from 'react-three-fiber';
import smokeImg from '../images/Smoke-Element.png';
import { Box } from '@mui/system';
import { PointsMaterial } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// Extend will make OrbitControls available as a JSX element called orbitControls for us to use.
extend({ OrbitControls });


const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame((state) => controls.current.update());
  return <orbitControls ref={controls} args={[camera, domElement]} autoRotate autoRotateSpeed={1.2}/>;
};



function Points(){
  let bufferRef = useRef();
  let colorsRef = useRef();
  let geomref = useRef();
  const count = 50;
  const sep = 2;
  let t = 0;
  let f = 0.001;
  let a = 3;

  const graph = useCallback((x,z,t) => {
   return Math.sin(f * (x**2 + z**2 + t)) * a
  }, [f,a])
  
  const color = new THREE.Color();

  let [positions, colors] = useMemo(()=>{
    let positions = new Float32Array((count*count)*4*3);
    let colors = new Float32Array(count*count*4*3);
    let i = 0;

    for(let xi = -count; xi < count; xi++){
      for(let zi = -count; zi < count; zi++){

        let y = parseFloat(graph(xi, zi, t));
        positions[i] = xi;
        positions[i+2] = zi;
        positions[i+1] = y;


  
        color.set(0xff0000);
       
        colors[i] = color.r/10;
        colors[i + 1] = color.g/10;
        colors[i + 2] = color.b/10;
        i+=3;
      }
    }
    return [positions, colors]
    
  }, [count, sep, graph])


  useFrame(()=>{
   t += 15;
   const positions = bufferRef.current.array;
   const colors = colorsRef.current.array;
   
   let i = 0;
   for(let xi = -count; xi < count; xi++){
    for(let zi = -count; zi < count; zi++){

      
      positions[i] = xi;
      positions[i+2] = zi;
      positions[i+1] = parseFloat(graph(xi,zi,t));

      let cmp = Math.abs((positions[i+1]/a));
      color.setRGB(cmp, cmp, cmp);
      
      colors[i] = color.r/3;
      colors[i + 1] = color.g/3;
      colors[i + 2] = color.b/3;


      i+=3;
    }
  }



  bufferRef.current.needsUpdate = true;
  colorsRef.current.needsUpdate = true;
  })

  return(
    <points>
      
      <bufferGeometry attach="geometry">
        <bufferAttribute
          ref = {bufferRef}
          attachObject={['attributes', 'position']}
          array={positions}
          count={positions.length/3}
          itemSize={3}
        />
        <bufferAttribute
          ref={colorsRef}
          attachObject={['attributes', 'color']}
          array={colors}
          count={colors.length/3}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial attach="material" vertexColors={true} size={3} sizeAttenuation={false} />
      
  
    </points>
  )
}


function AnimationCanvas(){
  return(
    <Canvas
      colorManagement
      camera={{position: [0.1, 0, 0], fov: 70}}
      
    >
      <CameraControls />
      {false && 
      <primitive object={new THREE.AxesHelper(10)} />
      }
        
       
      
      <Suspense fallback={null}>
        <Points/>
      </Suspense>
      
      

 
    </Canvas>
  )
}


export default AnimationCanvas;
