import React, { useState, useEffect } from 'react';


import axios from 'axios';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';

import ReactPlayer from 'react-player';

import qs from 'qs';
import spotifyImage from '../images/social_icons/spotify.svg'
import AnimationCanvasBox from './boxes_3d';
import { Helmet } from 'react-helmet';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import AudioPlayer from 'react-h5-audio-player';
import '../player.css'

const useStyles = makeStyles({

  show: {
    height: '150px',
    background: 'transparent',
    //background: 'green'
  },
  hide: {
    height: '50px',
    background: '#1d242d',
    paddingLeft: '5px',

  },
  section_heading: {
    opacity: 1,
    fontSize: '90px',
    fontFamily: 'Darkline',
    color: 'white',
    background: 'rgb(29, 36, 45, 1)',
    textAlign: 'center',
    margin: '0px',
    //padding: '10px',
    WebkitTransition: 'all .2s ease-out',
    transition: 'all .2s ease-out',

    left: '50%',
    top: '50%',

  },
  articlesContainer: {
    background: "rgb(29, 36, 45, 0.90)",
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover',
    backgroundPosition: "center center",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  coolTitile: {
    fontSize: '120px',
    color: "white",
    width: '100%',
    display: 'block',
    textAlign: 'center',
    fontFamily: 'Darkline'
  },

  tile_background: {
    background: 'black',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    zIndex: 1,
    filter: 'alpha(opacity=50)',
    opacity: .5,
  },
  textContent: {
    positon: 'relative',
    bottom: 0,
    width: '80%',
    fontSize: '24px',
    color: "white",
    fontFamily: 'Abel'
  },

  galleryTiles: {
    padding: '5%',
    background: 'rgba(119, 47, 26, 0.8)',
    marginTop: '30px'
  },
  galleryHolder: {
    display: 'block',
    position: 'fixed',
    top: 0,
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    background: 'rgba(77, 47, 71, 0.95)',
    zIndex: 10001,
  },
  close: {
    textAlign: 'right',
    color: 'white',
    fontSize: '30px',
    cursor: 'pointer',
    height: '50px',
    position: 'relative',

    padding: '5px',
  },
  imageTile: {
    width: '100%',
    height: 'auto',
  },
  sectionContainer: {
    marginBottom: '40px'
  },
  video_tile: {
    background: 'black',
    padding: '5px',
    height: "100%",
    cursor: "pointer",
  },
  image_thumb: {
    width: '100%',
    height: 'auto',
    bottom: 0,
    position: 'relative',
    display: 'inline-block'
  },
  fullWidthVideo: {
    color: 'white',
    backgroundColor: "rgba(33,33,33,.9)",
    zIndex: 10000,
    position: 'fixed',
    bottom: 0,
    top: 0,
    width: '100vw', /* viewport width */
    height: '100vh', /* viewport height */

    justifyContent: 'center',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  fullVideoContainer: {
    width: '80%',
    height: '80%',
    padding: '3%',
    margin: 'auto',
  },
  videoHeader: {
    color: '#6f1e9c',
    textAlign: 'right',
    height: '40px',
    padding: '10px',
  },
  album_tile: {
    background: "rgba(0,0,0,0.2)",
    margin: '5px',
    width: '100%',
    paddingBottom: '10px',
    borderRadius: "10px"
  },
  track_separator: {
    background: 'rgba(71, 71, 71, 0)',
    width: '92%',
    textAlign: 'left',
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: '30px',
    fontSize: '22px'

  },
  tracks_container: {
    padding: '20px'
  },
  track: {
    transition: 'all 0.5s ease',
    paddingLeft: '4%',
    paddingRight: '4%',
    borderRadius: '10px',
    '&:hover': {
      color: 'yellow',
      background: 'rgba(129, 177, 213, 0.2)',
    }
  },
  albumTitle: {
    padding: '5px',
    background: "rgba(120, 120, 120, 0.3)",
    borderTopLeftRadius: '10px',
  },
  spotify_external: {
    padding: '5px',
    background: "rgba(120, 120, 120, 0.3)",
    textAlign: "right",
    borderTopRightRadius: '10px',
  },

  video: {
    position: "fixed",
    left: '50%', /* % of surrounding element */
    top: '50%',
    transform: 'translate(-50%, -50%)', /* % of current element */
    zIndex: -1,
  },

  rhap_time: {
    color: 'white !important'
  },
  letvichka: {
    width: '40%',
    margin: 'auto',
    borderTop: '17px solid #420240'
}

})


const client_id = 'e4ac473829ce470eb1f5b89d65e84cbb';
const client_secret = 'fced977031244c34bc0db08df8e33cf9';


const Album = ({ album, spotifyToken }) => {
  const classes = useStyles();
  const [tracks, setTracks] = useState([]);


  const [totalDur, setTotalDur] = useState(0);

  useEffect(()=>{
    var total = 0;
    tracks.map((t)=>{
      total+=t.duration_ms;
      return null;
    })
    setTotalDur(Math.floor(total/60000))
  },[tracks]);


  
  useEffect(()=>{
    axios.get(
      `https://api.spotify.com/v1/albums/${album.id}/tracks`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + spotifyToken,
          'Content-Type': 'application/json',
        }
      }
    ).then(res => {setTracks(res.data.items);})
  },[album])

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  return (
    <div className={classes.album_tile}>
      {album &&
        <Grid container style={{ width: '100%', borderRadius: "10px" }}>
          <Grid item xs={8} className={classes.albumTitle}>{album.name}</Grid>
          <Grid item xs={4} className={classes.spotify_external}>Listen on <img src={spotifyImage} onClick={() => window.open(album.external_urls.spotify, "_blank")}
            style={{ width: '20px', height: '20px', cursor: 'pointer' }} alt="spotify Icon" /></Grid>

          <Grid item lg={4} md={4} sm={12} xs={12}>
            <BrowserView>
            <AnimationCanvasBox img={album?.images[0].url} height={300} id={'album_animation_canvas_' + album.id} />
            </BrowserView>
            <MobileView>
              <img src={album?.images[0].url} style={{width: '100%'}}/>
            </MobileView>

          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>

            <div className={classes.track_separator}>Performed by: {album.artists.map((a) => { return (a.name) }).join(' & ')} <br />
              Release date: {new Date(album.release_date).getDate()} {months[(new Date(album.release_date)).getMonth()]} {new Date(album.release_date).getFullYear()}<br />
              Duration: {totalDur} min.</div>
            <div className={classes.tracks_container}>
              {tracks.map((t) => {
                return (
                  <div className={classes.track}>
                    <div>{t.name}</div>
            
                    <AudioPlayer
                      src={t.preview_url}
                      showJumpControls={false}
                      showSkipControls={false}
                      customAdditionalControls={[]}
                      // other props here
                    />
                  </div>
                )
              })}
            </div>
          </Grid>
        </Grid>
      }

    </div>
  )
}


export default function Music(props) {
  const classes = useStyles();
  const [albums, setAlbums] = useState([])
  const [spotifyToken, setSpotifyToken] = useState(null)

  useEffect(() => {
    const headers = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      auth: {
        username: client_id,
        password: client_secret,
      },
    };
    const data = {
      grant_type: 'client_credentials',
    };

    axios.post(
      'https://accounts.spotify.com/api/token',
      qs.stringify(data),
      headers
    ).then(res => setSpotifyToken(res.data.access_token))

  }, [])



  useEffect(() => {
    axios.get(
      'https://api.spotify.com/v1/artists/6n7ZUZfIHRIJU23LuIzTlx/albums',
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + spotifyToken,
          'Content-Type': 'application/json',
        }
      }
    ).then(res => setAlbums(res.data.items))

  }, [spotifyToken])


  return (
    <div>
      <Helmet>
       <title>Evgeny Genchev - Music</title>
       <meta name="description" content="" />
       <script src="https://apis.google.com/js/platform.js"></script>
     </Helmet>

      <div className={classes.sectionContainer}>
        
        <div className={classes.section_heading}>
        <div style={{width: '100%', height: '5px'}}>
                        <div className={classes.letvichka}>.</div>
                    </div>
                Latest Releases</div>
        <div className={classes.articlesContainer}>
          <div className={classes.textContent}>
      
              {albums?.map((a,inda) => {
                return (
                  <Album album={a} spotifyToken={spotifyToken} id={'album_'+inda}/>
                )
              })}
        
          </div>
        </div>
      </div>
    </div>
  );
}