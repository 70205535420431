import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { ExpandLess } from '@mui/icons-material';


const useStyles = makeStyles({
 toTopContainer: {
   zIndex: 999999,
   position: 'fixed',
   bottom: '20px',
   right: '20px',
   
 },
 toTop: {
  zIndex: 999999,
   position: 'fixed',
   
   backgroundColor: "#DCDCDC !important",
   transition: '0.3s',
   color: 'black !important',
   '&:hover, &.Mui-focusVisible': {
    transition: '0.3s',
    color: '#397BA6 !important',
    backgroundColor: '#DCDCDC !important'
   }
 }
 
})

const Scroll = ({ showBelow }) => {
 const classes = useStyles()
 const [show, setShow] = useState(showBelow?false:true);
 const handleClick = () => {
  window['scrollTo']({top: 0, behavior: 'smooth'})
 }
 const handleScroll = () => {
  if(window.pageYOffset > showBelow){
   if(!show) setShow(true);
  }else{
   if(show) setShow(false);
  }
 }
 useEffect(()=>{
  if(showBelow) {
   window.addEventListener('scroll', handleScroll);
   return() => window.removeEventListener('scroll', handleScroll)
  }
 })

 return (
  <div className={classes.toTopContainer}>
   {show &&
    <IconButton onClick={handleClick} className={classes.toTop}>
     <ExpandLess />
    </IconButton>
   }
  </div>
 )
}

export default Scroll