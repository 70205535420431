import './App.css';
import AnimatedTile from './components/AnimatedTile';
import TopNav from './components/TopNav'
import Footer from './components/Footer'
import Articles from './components/Articles'
import About from './components/About'
import Tour from './components/Tour'
import Videos from './components/Videos'
import Music from './components/Music'
import NotFound from './components/NotFound'
import ContactForm from './components/ContactForm'
import PrivacyPolicy from './components/PrivacyPolicy'

import { Grid } from '@mui/material';

import ImageParser from 'react-image-parser';
import { usePalette } from 'react-palette'
import React, { useState, useEffect, useRef, useMemo, Suspense } from 'react';
import ArticleForm from './components/ArticleForm';
import './fonts/Darkline/Darkline.ttf';
import { Parallax } from 'react-parallax';
import Admin from './components/Admin';
import FullArticle from './components/FullArticle';
import Unsubscribe from './components/Unsubscribe';

import * as THREE from "three";
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry'
import HelvetikerFont from 'three/examples/fonts/helvetiker_regular.typeface.json';
import { Canvas, useLoader } from 'react-three-fiber';
import AllArticles from './components/AllArticles';

import FestivalInfoAndPayment from './components/SSF';

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Outlet,
  useLocation,
  Navigate
} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { gsap } from 'gsap';
import { Helmet } from "react-helmet";
import Home from './components/Home';
import JumboFest from './components/SSF_jumbo';
import smokeImg from './images/Smoke-Element.png'

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import ssf_logo_small from './images/Sweet_Struggle_FESTIVAL_logo_small.png'
import ssbg from './images/ss_bgd.jpg'
import Button from '@mui/material/Button';

const useStyles = makeStyles({
  main_content: {
    background: '#1d242d',
  },
  hcenter: {
    WebkitTransition: 'all .2s ease-out',
    transition: 'all .2s ease-out',
    left: '50%',
    top: '50%',
    position: 'absolute',
    fontSize: '120px',
    color: "white",
    textAlign: 'center',
    fontFamily: 'Darkline',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: 'grey',
  },
  preloader: {
    display: 'none'
  },
  shutterHolder: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%'
  },
  leftShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',

  },
  rightShutter: {
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 101,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.6)',
  },
  btn_link: {
    background: '#420240',
    textDecoration: 'none',
    color: 'white',
    padding: '10px',
    borderRadius: '10px',
    fontSize: '20px',
    marginTop: '20px',
    display: 'inline-block'
  },
  jumbotron: {
    WebkitTransition: 'all .2s ease-out',
    transition: 'all .2s ease-out',
    background: 'url(../ss_bgd.jpg)',
    backgroundRepeat: 'no-repeat',
    textAlign: 'center',
    padding: '50px',
    color: 'white',
    fontSize: '30px',
    fontWeight: 600,
    marginBottom: '50px',
    backgroundSize: 'cover',
    '@media (max-width: 450px)' : {
      fontSize: '20px',
    }
  },
  bigletter: {
    fontSize: '50px',
    '@media (max-width: 450px)' : {
      fontSize: '40px',
    }
  },
  closeBTN: {
    background: 'red',
    color: 'white',
  }
})

function Points(){
  const imgTex = useLoader(THREE.TextureLoader, smokeImg);
  const count = 100;
  const sep = 3;
  let positions = useMemo(()=>{
    let positions = [];
    for(let xi =0; xi < count; xi++){
      for(let zi = 0; zi < count; zi++){
        let x = sep * (xi - count/2);
        let z = sep * (zi - count/2);
        let y = 0;
        positions.push(x,y,z)
      }
    }
    return new Float32Array(positions)
  }, [count, sep])
  return(
    <points>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={positions}
          count={positions.length}
          itemSize={3}
        />
      </bufferGeometry>
      <pointsMaterial 
      attach='material'
      map={imgTex}
      color={0x00aaff}
      size={0.5}
      sizeAttenuation
      transparent={false}
      alpha={0.5}
      opacity={1.0}
      >

      </pointsMaterial>
    </points>
  )
}


function App() {
  return (
 
    <Router>
      <Routes>
        <Route path="/" element={<><Home /></>}>
          <Route path="home" element={<><Articles /><Tour /></>}>
            
          </Route>
          <Route path="articles/:article_name" element={<FullArticle />} />
          <Route path="music" element={<Music />} />
          <Route path="tour" element={<Tour />} />
          <Route path="admin" element={<Admin />} />
          <Route path="about" element={<About />} />
          <Route path="meet-the-band" element={<About />} />
          <Route path="meet-the-band/" element={<About />} />
          <Route path="contact" element={<ContactForm />} />
          <Route path="videos" element={<Videos />} />
          <Route path="allArticles" element={<AllArticles />} />
          <Route path="ssf" element={<FestivalInfoAndPayment />} />
          <Route path="privacy" element={<PrivacyPolicy />} />
          <Route path="unsubscribe/:uid" element={<Unsubscribe />} />
           
        </Route>
      </Routes>
    </Router>
  
  );
}

export default App;
